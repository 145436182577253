import React, { memo, useState } from 'react';
import { Input, InputWithValidation, RequiredIndicatorType, TOOLTIP_POSITIONS, ValidationMode } from '@ftbpro/mm-admin-ui-components';
import { COMMON_VALIDATORS } from 'core/utils/validationsBeta/validations';

import { css } from '@emotion/core';
import { useStyles } from '../../../../core/hooks/useStyles';

import { getStylesObject } from './croppingArea/styles/croppingArea.styles';
import { imageInfoDataStyles } from '../../../mediaLibrary/images/components/imageInfo/ImageInfoBody/ImageInfoData/ImageInfoData.styles';
import { MediaLibraryInputWithChip } from '../../../mediaLibrary/commonComponents/MediaLibraryInputWithChip/MediaLibraryInputWithChip';

const CAPTION_PLACEHOLDER = 'How about a caption? (Optional)';
const CREDIT_PLACEHOLDER = 'How about a credit? (Optional)';

const AUDIT_LABEL = ' Audit trail';
const TAGS_LABEL = 'Tags';

const TITLE_MAX_LENGTH = 500;

const ALT_MAX_LENGTH = 125;
const ALT_PLACEHOLDER = 'Enter alt text';
const ALT_LABEL = 'Alt text';
const ALT_TOOLTIP = 'Use the most relevant keywords to describe the image naturally to improve SEO. If this field remains empty, the caption will be used as the image’s alt.';

export const ImageDescriptionComponent = ({
  caption,
  credit,
  alt,
  audit,
  tags,
  isCreditEditingEnabled,
  onChangeCaption,
  onKeyDown,
  handleAddTag,
  handleRemoveTag,
  onChangeCredit,
  onChangeAlt,
  onChangeAudit,
  isEditingBlock,
  shouldShowAuditAndTags,
}) => {
  const styles = useStyles(getStylesObject, []);
  const [altValidation, setAltValidation] = useState({ value: alt, error: null });

  const handleValidation = validationResult => {
    setAltValidation(validationResult);
    onChangeAlt(null, altValidation.value);
  };

  return (
    <div css={styles.descriptionWrapper}>
      <div css={styles.captionInputWrapper}>
        <Input
          maxLength={TITLE_MAX_LENGTH}
          onChange={onChangeCaption}
          value={caption}
          placeholder={CAPTION_PLACEHOLDER}
          onKeyDown={onKeyDown}
          shouldFocus
          labelProps={{ text: CAPTION_PLACEHOLDER }}
          variables={{ width: '100%' }}
          multiline
          rows={1}
          autoHeight
        />
      </div>
      <div css={styles.altInputWrapper}>
        <InputWithValidation
          maxLength={ALT_MAX_LENGTH}
          value={altValidation.value}
          error={altValidation.error}
          placeholder={ALT_PLACEHOLDER}
          onKeyDown={onKeyDown}
          labelProps={{
            requiredIndicator: RequiredIndicatorType.Required,
            text: ALT_LABEL,
            tooltip: ALT_TOOLTIP,
            tooltipMultiline: true,
            tooltipPosition: TOOLTIP_POSITIONS.RIGHT,
          }}
          validators={[COMMON_VALIDATORS.mandatory]}
          validationMode={[ValidationMode.OnBlur, ValidationMode.OnChange]}
          onValidation={handleValidation}
          variables={{ width: '100%' }}
          multiline
          rows={1}
          autoHeight
        />
      </div>
      <div css={styles.creditInputWrapper}>
        <Input
          onChange={onChangeCredit}
          value={credit}
          placeholder={CREDIT_PLACEHOLDER}
          onKeyDown={onKeyDown}
          labelProps={{ text: CREDIT_PLACEHOLDER }}
          disabled={!isCreditEditingEnabled}
          variables={{ width: '100%' }}
          rows={1}
          autoHeight
          multiline
        />
      </div>
      {
        !isEditingBlock && shouldShowAuditAndTags ? (
          <>
            <div css={styles.creditInputWrapper}>
              <Input
                onChange={onChangeAudit}
                value={audit}
                placeholder={AUDIT_LABEL}
                onKeyDown={onKeyDown}
                labelProps={{ text: AUDIT_LABEL }}
                variables={{ width: '100%' }}
              />
            </div>
            <div css={styles.creditInputWrapper}>
              <div css={css(imageInfoDataStyles.descriptionWrapperInput)}>
                <MediaLibraryInputWithChip
                  chipList={tags}
                  style={imageInfoDataStyles.descriptionWrapperInputChip}
                  name={TAGS_LABEL}
                  placeholder={TAGS_LABEL}
                  labelProps={{
                  text: TAGS_LABEL,
                  tooltip: TAGS_LABEL,
                }}
                  onRemove={value => handleRemoveTag({ tagToRemove: value })}
                  onAdd={value => handleAddTag({ tagToAdd: value })}
                />
              </div>
            </div>
          </>
        ) : null
      }

    </div>
  );
};

export const ImageDescription = memo(ImageDescriptionComponent, (prevProps, nextProps) => {
  return !(prevProps.caption !== nextProps.caption
    || prevProps.credit !== nextProps.credit
    || prevProps.alt !== nextProps.alt
    || prevProps.isCreditEditingEnabled !== nextProps.isCreditEditingEnabled
    || prevProps.audit !== nextProps.audit
    || prevProps.tags !== nextProps.tags);
});
