import { getNewEchoState } from '../newEcho.selector';

export function getEchoCustomizationState(state) {
  return getNewEchoState(state).echoCustomization;
}

function getPostMetaData(state) {
  return getEchoCustomizationState(state).postMetaData;
}

function getPostData(state) {
  return getEchoCustomizationState(state).postData || {};
}

function getPostFeeds(state) {
  return getEchoCustomizationState(state).feeds;
}

export const echoCustomizationSelector = {
  isPostDataLoading(state) {
    return getEchoCustomizationState(state).isPostDataLoading;
  },
  getPostMetaData(state) {
    return getEchoCustomizationState(state).postMetaData;
  },
  getMaddenData(state) { // TODO: Remove once madden migration is done.
    return getEchoCustomizationState(state).maddenData;
  },
  isPublishing(state) {
    return getEchoCustomizationState(state).isPublishing;
  },
  originalPostData(state) {
    return getEchoCustomizationState(state).originalPostData;
  },
  postData(state) {
    return getEchoCustomizationState(state).postData;
  },
  postMetaData(state) {
    return getPostMetaData(state);
  },
  areAvailableFeedsLoading(state) {
    return getEchoCustomizationState(state).areAvailableFeedsLoading;
  },
  feeds(state) {
    return getPostFeeds(state);
  },
  availableFeeds(state) {
    return getPostFeeds(state).availableFeeds;
  },
  addedFeeds(state) {
    return getPostFeeds(state).addedFeeds;
  },
  featuredFeeds(state) {
    return getPostFeeds(state).featuredFeeds;
  },
  scheduledFeeds(state) {
    return getPostFeeds(state).scheduledFeeds;
  },
  postPath(state) {
    return getPostMetaData(state).postPath;
  },
  areAdsCategoriesLoading(state) {
    return getPostMetaData(state).areAdsCategoriesLoading;
  },
  availableAdsCategories(state) {
    return getPostMetaData(state).availableAdsCategories;
  },
  noIndex(state) {
    return getPostMetaData(state).noIndex;
  },
  excludeFromRSS(state) {
    return getPostMetaData(state).excludeFromRSS;
  },
  excludeFromRecommendations(state) {
    return getPostMetaData(state).excludeFromRecommendations;
  },
  hideOwner(state) {
    return getPostMetaData(state).hideOwner;
  },
  isSponsoredContentEnabled(state) {
    return getPostMetaData(state).isSponsoredContentEnabled;
  },
  sponsoredContent(state) {
    return getPostMetaData(state).sponsoredContent;
  },
  isCanonicalUrlEnabled(state) {
    return getPostMetaData(state).isCanonicalUrlEnabled;
  },
  canonicalUrl(state) {
    return getPostMetaData(state).canonicalUrl;
  },
  commercialTags(state) {
    return getPostMetaData(state).commercialTags;
  },
  getTopic(state) {
    return getPostMetaData(state).topic;
  },
  getSupportsCustomTopic(state) {
    return getPostMetaData(state).supportsCustomTopic;
  },
  getVertical(state) {
    return getPostMetaData(state).vertical;
  },
  getSupportsCustomVertical(state) {
    return getPostMetaData(state).supportsCustomVertical;
  },
  areAvailableAuthorsLoading(state) {
    return getEchoCustomizationState(state).areAvailableAuthorsLoading;
  },
  availableAuthors(state) {
    return getEchoCustomizationState(state).availableAuthors;
  },
  authors(state) {
    return getPostData(state).authors;
  },
  suggestedPages(state) {
    return getEchoCustomizationState(state).suggestedPages;
  },
  suggestedPagesError(state) {
    return getEchoCustomizationState(state).suggestedPagesError;
  },
  isPropertySettingLoad(state) {
    return getPostMetaData(state).propertySettingLoad;
  },
  isLoadingPages(state) {
    return getEchoCustomizationState(state).isLoadingPages;
  },
  isManualRecommendationsEnabled(state) {
    return getPostMetaData(state).isManualRecommendationsEnabled;
  },
  isBettingContentEnabled(state) {
    return getPostMetaData(state).isBettingContentEnabled;
  },
  bettingContent(state) {
    return getPostMetaData(state).bettingContent;
  },
  recommendedArticles(state) {
    return getPostMetaData(state).recommendedArticles;
  },
  selectedRecommendedArticles(state) {
    return getPostMetaData(state).selectedRecommendedArticles;
  },
  isRecommendationArticlesLoading(state) {
    return getPostMetaData(state).isRecommendationArticlesLoading;
  },
  recommendedArticlesError(state) {
    return getPostMetaData(state).recommendedArticlesError;
  },
  additionalPostsTopic(state) {
    return getPostMetaData(state).additionalPostsTopic;
  },
  featuredArticlesForCurrentPost(state) {
    return getPostMetaData(state).featuredArticlesForCurrentPost;
  },
  isFeaturedArticlesForCurrentPostLoading(state) {
    return getPostMetaData(state).isFeaturedArticlesForCurrentPostLoading;
  },
  featuredArticlesForCurrentPostError(state) {
    return getPostMetaData(state).featuredArticlesForCurrentPostError;
  },
  isPublishDateEnabled(state) {
    return getPostMetaData(state).isPublishDateEnabled;
  },
  getPublishDate(state) {
    return getPostMetaData(state).publishDate;
  },
  getMainCategories(state) {
    return getPostMetaData(state).mainCategories;
  },
  getSelectedMainCategory(state) {
    return getPostMetaData(state)?.mainCategories?.selectedMainCategory;
  },
  getSelectedInThisStory(state) {
    return getPostMetaData(state).inThisStory;
  },
};
