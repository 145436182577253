import { getLocalStorageData } from '@ftbpro/mm-admin-core-utils';
import { Logger } from '../../logger';
import { ConfigsProvider } from '../../configs/ConfigsProvider';
import { PropertiesByRolesItem, PropertiesListItem } from './property.types';
import { VoltaxRole } from '../user/roles.constants';

export const getCurrentPropertyLocalStorageKey = (userId: string, product: string) => `${userId}_${ConfigsProvider.TENANT}_${product}CurrentProperty`;

export const isPropertyExists = (availableProperties: PropertiesByRolesItem[], slug: string) => availableProperties.some(property => property.slug === slug);

export const getFirstOrLastSelectedProperty = (availableProperties: PropertiesByRolesItem[], key: string) => {
  const propertyFromLocalStorage = getLocalStorageData(key);
  const defaultProperty = availableProperties[0];
  if (propertyFromLocalStorage) {
    try {
      const parsedProperty = JSON.parse(propertyFromLocalStorage);
      return isPropertyExists(availableProperties, parsedProperty.slug) ? parsedProperty : defaultProperty;
    } catch (error) {
      Logger.error(error);
      return defaultProperty;
    }
  }
  return defaultProperty;
};

export const formatHqProperties = (properties: PropertiesListItem[]) => {
  return properties.reduce((acc, property) => ({
    ...acc,
    [property.id]: property,
  }), {});
};

const VMS_ROLES = [
  VoltaxRole.VmsEditor,
  VoltaxRole.VmsExplorer,
  VoltaxRole.VmsManager,
  VoltaxRole.PlayerHBTab,
  VoltaxRole.PlayerMonetizationTab,
];

export const filterOutSiTemp = (userRolePropertiesMap: any = {}) => {
  const filteredUserRolePropertiesMap: Record<string, Record<string, object>> = {};
  Object.keys(userRolePropertiesMap).forEach((roleId: string) => {
    filteredUserRolePropertiesMap[roleId] = userRolePropertiesMap[roleId];
    !VMS_ROLES.includes(roleId as VoltaxRole) && delete filteredUserRolePropertiesMap[roleId]['si-temp'];
  });

  return filteredUserRolePropertiesMap;
};
