import { AB_TESTS_ROUTES } from './abTesting/abTesting.constants';
import { PLAYLIST_ROUTES_SCHEMA } from './playlist/playlist.constants';

export const ROUTE_VMS_PREFIX = '/vms';
export const ROOT_SLICE = 'vms';
export const ROUTE_VMS_NEW_PAGE_SUFFIX = 'new';
export const QUERY_PARAMS_INJECTION_ID = 'injection_id';
export const QUERY_PARAMS_BULK_EDIT_IDS = 'ids';
export const QUERY_PARAMS_TENANT = 'tenant';
export const QUERY_PARAMS_ORGANIZATION = 'organization';
export const QUERY_PARAMS_PROPERTY = 'property';

export const VMS_ROUTES_SCHEMA = {
  IMPORTS: `${ROUTE_VMS_PREFIX}/imports`,
  NEW_IMPORT: `${ROUTE_VMS_PREFIX}/imports/:property/${ROUTE_VMS_NEW_PAGE_SUFFIX}`,
  EDIT_IMPORT: `${ROUTE_VMS_PREFIX}/imports/:property/:id`,
  VIDEOS: `${ROUTE_VMS_PREFIX}/videos`,
  EDIT_VIDEO: `${ROUTE_VMS_PREFIX}/videos/:property/:id`,
  VIDEO_REPORTS: `${ROUTE_VMS_PREFIX}/reports`,
  VIDEO_REPORTS_DRILL_DOWN: `${ROUTE_VMS_PREFIX}/reports/:property/:id/:title`,
  PLAYLISTS: PLAYLIST_ROUTES_SCHEMA.PLAYLISTS,
  RESTRICTIONS: `${ROUTE_VMS_PREFIX}/restrictions`,
  NEW_RESTRICTION: `${ROUTE_VMS_PREFIX}/restrictions/${ROUTE_VMS_NEW_PAGE_SUFFIX}`,
  VIEW_RESTRICTION: `${ROUTE_VMS_PREFIX}/restrictions/:property/:id`,
  PLAYERS: `${ROUTE_VMS_PREFIX}/players`,
  NEW_PLAYER: `${ROUTE_VMS_PREFIX}/players/${ROUTE_VMS_NEW_PAGE_SUFFIX}`,
  EDIT_PLAYER_VOLTAX: `${ROUTE_VMS_PREFIX}/players/:id`,
  EDIT_PLAYER_HQ: `${ROUTE_VMS_PREFIX}/players/:tenant/:organization/:property/:id`,
  BULK_EDIT_PLAYER_HQ: `${ROUTE_VMS_PREFIX}/players/bulk/edit`,
  BULK_EDIT_PLAYER_HQ_PAGE: `${ROUTE_VMS_PREFIX}/players/bulk/edit/:tenant/:organization/:property`,
  BULK_ERRORS_PLAYER_HQ_PAGE: `${ROUTE_VMS_PREFIX}/players/bulk/errors/:bulkId`,
  INJECTIONS: `${ROUTE_VMS_PREFIX}/injections`,
  BULK_STATUS: `${ROUTE_VMS_PREFIX}/videos/bulk/:property/:id`,
  BULK_EDIT: `${ROUTE_VMS_PREFIX}/videos/bulk/edit`,
  BULK_EDIT_PAGE: `${ROUTE_VMS_PREFIX}/videos/bulk/edit/:property`,
  VIDEO_BUILDER: `${ROUTE_VMS_PREFIX}/video-builder`,
  NEW_VIDEO_BUILDER: `${ROUTE_VMS_PREFIX}/video-builder/${ROUTE_VMS_NEW_PAGE_SUFFIX}`,
  EDIT_VIDEO_BUILDER_HQ: `${ROUTE_VMS_PREFIX}/video-builder/:tenant/:organization/:property/:id`,
  VERSION_MANAGEMENT: `${ROUTE_VMS_PREFIX}/versionManagement`,
  SSP_MAKER: `${ROUTE_VMS_PREFIX}/ssp`,
  SSP_MAKER_GROUPS: `${ROUTE_VMS_PREFIX}/ssp/groups`,
  SSP_MAKER_EDIT_GROUP_PAGE: `${ROUTE_VMS_PREFIX}/ssp/groups/:id`,
  AB_TEST: `${ROUTE_VMS_PREFIX}${AB_TESTS_ROUTES.BASE}`,
  NEW_AB_TEST: `${ROUTE_VMS_PREFIX}${AB_TESTS_ROUTES.NEW}`,
  EDIT_AB_TEST: `${ROUTE_VMS_PREFIX}${AB_TESTS_ROUTES.EDIT}`,
  EDIT_AB_TEST_PAGE: `${ROUTE_VMS_PREFIX}${AB_TESTS_ROUTES.EDIT}/:id`,
  TARGETING: `${ROUTE_VMS_PREFIX}/targeting`,
};

export const ENCRYPTION = {
  KEY_FORMAT: 'raw' as const,
  KEY_USAGE: 'deriveKey' as KeyUsage,
  ENCRYPT: 'encrypt' as KeyUsage,
  DECRYPT: 'decrypt' as KeyUsage,
  ENCRYPT_ALGO: 'AES-GCM',
  ENCRYPT_KEY_LENGTH: 256,
  ALGO: 'PBKDF2',
  SALT: 'salt',
  ITERATIONS: 100000,
  HASH: 'SHA-256',
  IV_LENGTH: 12,
};
