import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { isEmpty } from '@ftbpro/mm-admin-core-utils';
import { pushGlobalNotificationActionCreator } from '../../globalNotifications/store/globalNotifications.actions';
import { AdministrationServiceConfigsProvider } from '../../services/administrationServiceConfigsProvider';
import { Product } from '../../constants';
import { networkAction } from '../../middleware/networkMiddleware/networkMiddleware.actions';

const FETCH_ERROR_TEXT = 'Sorry, we couldn\'t load any organizations';

const SLICE_NAME = 'organization';

export interface OrganizationListItem {
  id: string;
  name: string;
  products?: Product[] | null;
  properties?: string[] | null;
}
interface ProductItem {
  id: Product,
  name: string
}
export interface OrganizationListItemFromServer {
  id: string;
  name: string;
  products?: ProductItem[] | null;
  properties?: string[] | null;
}

interface SliceState {
  availableOrganizations: OrganizationListItem[],
  availableTenants: string[],
  areOrganizationLoading: boolean,
  error?: string,
  currentTenant?: string,
}

export type OrganizationSliceState = SliceState & Partial<Record<Product, {
  currentOrganization: OrganizationListItem | null
}>>

export const initialState: OrganizationSliceState = {
  availableOrganizations: [],
  availableTenants: [],
  areOrganizationLoading: false,
  error: undefined,
  currentTenant: undefined,
};

const organizationSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setCurrentTenant(state, action) {
      state.currentTenant = action.payload;
    },
    setAvailableTenants(state, action) {
      const tenant = action.payload[0];
      state.availableTenants = action.payload;
      state.currentTenant = tenant;
    },
    setCurrentOrganization(state, action) {
      const { selectedOrganization, product } = action.payload;
      state[product as Product] = { currentOrganization: selectedOrganization };
    },
    resetAvailableOrganizations(state, action) {
      const { product } = action.payload;
      state.availableOrganizations = [];
      state[product as Product] = { currentOrganization: null };
    },
    fetchOrganizationsByTenantPending(state) {
      state.areOrganizationLoading = true;
    },
    fetchOrganizationsByTenantSuccess(state, action) {
      state.areOrganizationLoading = false;
      state.availableOrganizations = action.payload.organizations.map((org: OrganizationListItemFromServer) => (
        org.products ? { ...org,
           products: isEmpty(org.products) ? []
            : org.products?.map((product : ProductItem) => product.id) } : org));
    },
    fetchOrganizationsByTenantRejected(state, action) {
      state.areOrganizationLoading = false;
      state.availableOrganizations = [];
      state.error = action.payload;
    },
  },
});

export const fetchOrganizationsByTenant = createAsyncThunk<void,
  undefined>(
  `${SLICE_NAME}/fetchOrganizationsByTenant`,
  async (arg, thunkAPI) => {
    const { dispatch } = thunkAPI;
    await dispatch(networkAction({
      url: AdministrationServiceConfigsProvider.getTenantOrganizationsEndpoint(),
      onPending: fetchOrganizationsByTenantPending,
      onSuccess: fetchOrganizationsByTenantSuccess,
      onFailure: error => {
        dispatch(pushGlobalNotificationActionCreator({
          text: FETCH_ERROR_TEXT,
          status: SNACK_BAR_STATUSES.ERROR,
          stayOpen: true,
        }));
        return { type: fetchOrganizationsByTenantRejected.type, payload: error };
      },
    }));
  },
);

export const {
  setCurrentTenant,
  setCurrentOrganization,
  setAvailableTenants,
  resetAvailableOrganizations,
  fetchOrganizationsByTenantPending,
  fetchOrganizationsByTenantSuccess,
  fetchOrganizationsByTenantRejected,
} = organizationSlice.actions;

export default organizationSlice.reducer;
