import { PropertySliceState } from './property.types';
import { Product } from '../../constants';

const getPropertyData = (state: any) => state.property as PropertySliceState;

export const propertySelector = {
  availablePropertiesHQ: (state: any) => getPropertyData(state).availablePropertiesHQ,
  availablePropertiesVoltax: (state: any) => getPropertyData(state).availablePropertiesVoltax,
  currentProperty: (state: any, product: Product) => getPropertyData(state)[product]?.currentProperty,
  arePropertiesLoading: (state: any) => getPropertyData(state).arePropertiesLoading,
  currentPropertySlug: (state: any, product: Product) => getPropertyData(state)[product]?.currentProperty?.slug,
  currentPropertySlugFunc: (state: any) => (product: Product) => getPropertyData(state)[product]?.currentProperty?.slug,
  error: (state: PropertySliceState) => getPropertyData(state).error,
};
