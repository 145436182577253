import { mmNetworkService } from '../../../../../core/network/mmServicesApiProvider';
import { EditorServiceDataProvider } from '../editorServiceConfigsProvider';
import { extractIdFromMedia } from '../../utils/mediaIdExtractor';

export function sanitizeScriptOutOfHtmlString(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  return [...div.children].filter(child => child.tagName.toLowerCase() !== 'script').map(item => item.outerHTML).reduce((current, output) => output + current, '');
}

export function getOembedObjectFromOEmbedResponse(oEmbedData, originalEmbedUrl, blockType) {
  const url = oEmbedData.url || originalEmbedUrl;
  return {
    type: oEmbedData.type,
    embedType: oEmbedData.embedType,
    url,
    thumbnailUrl: oEmbedData.thumbnail_url,
    provider: oEmbedData.provider_name.toLowerCase(),
    credit: oEmbedData.author_name,
    caption: oEmbedData.title,
    description: oEmbedData.title,
    height: oEmbedData.height ? oEmbedData.height.toString() : oEmbedData.height,
    width: oEmbedData.width ? oEmbedData.width.toString() : oEmbedData.width,
    html: sanitizeScriptOutOfHtmlString(oEmbedData.html),
    originalEmbedUrl,
    mediaId: extractIdFromMedia(blockType, { ...oEmbedData, url }),
  };
}

function getMMPlayerOembedObjectFromOEmbedResponse(oEmbedData, playerId, mediaId) {
  return {
    duration: `${oEmbedData.duration}`,
    type: oEmbedData.type,
    url: oEmbedData.url,
    thumbnailUrl: oEmbedData.thumbnail_url,
    height: oEmbedData.height ? oEmbedData.height.toString() : oEmbedData.height,
    width: oEmbedData.width ? oEmbedData.width.toString() : oEmbedData.width,
    html: sanitizeScriptOutOfHtmlString(oEmbedData.html),
    previewHtml: oEmbedData.preview_html,
    version: oEmbedData.version,
    caption: oEmbedData.caption,
    creator: oEmbedData.creator,
    description: oEmbedData.description,
    provider: oEmbedData.provider.toLowerCase(),
    mediaId,
    playerId,
  };
}

function getConfigForOembedProxyRequest(url) {
  return {
    method: 'POST',
    body: JSON.stringify({
      url,
      method: 'GET',
    }),
  };
}

export async function getOembedData(oEmbedUrl, originalEmbedUrl, blockType, isJsonpRequired = false) {
  const configs = {
    method: 'GET',
  };
  const fetchMethod = isJsonpRequired ? mmNetworkService.jsonpExternalFetch : mmNetworkService.externalFetch;
  const res = await fetchMethod(oEmbedUrl, configs);
  const oEmbedData = await res.json();
  return getOembedObjectFromOEmbedResponse(oEmbedData, originalEmbedUrl, blockType);
}

export async function getOembedDataByProxy(oEmbedUrl, originalEmbedUrl, blockType, property) {
  try {
    const encodedUrl = encodeURI(oEmbedUrl);
    const oEmbedProxyRequestConfig = getConfigForOembedProxyRequest(oEmbedUrl);
    const oEmbedData = await mmNetworkService.fetch(`${EditorServiceDataProvider.getProxyEndpoint(property)}?url=${encodedUrl}`, oEmbedProxyRequestConfig);
    return getOembedObjectFromOEmbedResponse(oEmbedData, originalEmbedUrl, blockType);
  } catch (e) {
    return e;
  }
}

export async function getMMPlayerOembedDataByProxy(oEmbedUrl, playerId, mediaId, property) {
  try {
    const encodedUrl = encodeURI(oEmbedUrl);
    const oEmbedProxyRequestConfig = getConfigForOembedProxyRequest(oEmbedUrl);
    const oEmbedData = await mmNetworkService.fetch(`${EditorServiceDataProvider.getProxyEndpoint(property)}?url=${encodedUrl}`, oEmbedProxyRequestConfig);
    return getMMPlayerOembedObjectFromOEmbedResponse(oEmbedData, playerId, mediaId);
  } catch (e) {
    throw new Error(e);
  }
}

export async function getMiniPlayerOembedDataByProxy(oEmbedUrl, mediaId, property) {
  try {
    const encodedUrl = encodeURI(oEmbedUrl);
    const oEmbedProxyRequestConfig = getConfigForOembedProxyRequest(oEmbedUrl);
    const oEmbedData = await mmNetworkService.fetch(`${EditorServiceDataProvider.getProxyEndpoint(property)}?url=${encodedUrl}`, oEmbedProxyRequestConfig);
    return getMMPlayerOembedObjectFromOEmbedResponse(oEmbedData.data, '', mediaId);
  } catch (e) {
    throw new Error(e);
  }
}
