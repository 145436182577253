import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Input } from '@ftbpro/mm-admin-ui-components';
import { css } from '@emotion/core';
import { Plugin } from '../../Plugin/Plugin';
import { pluginInputStyle } from '../../shared/styles/pluginInput.styles';
import { pluginAddButtonTextHandler, urlInputPlaceholderGenerator } from '../../../utils/plugins.utils';
import { getStylesObject } from '../../styles/embed.styles';
import { getOembedDataByProxy } from '../../../services/oembed/oEmbedApiProvider';
import { BLOCK_TYPES } from '../../../utils/blocksDescriptorGenerator';
import { isEnterPressed } from '../../../../../../core/utils/keyboard.utils';
import { Logger } from '../../../../../../core/logger';
import { CrowdSignalPanelComponentProps, CrowdSignalValue } from '../crowdSignal.types';

const ENTER_CROWD_SIGNAL_PLACEHOLDER = urlInputPlaceholderGenerator('Crowd Signal');
const EMBED_URL_BROKEN_TEXT = 'The URL is broken. Please try another one.';
const OEMBED_URL_PREFIX = 'https://api.crowdsignal.com/oembed?url=';

export const CrowdSignalPanelComponent = (props: CrowdSignalPanelComponentProps) => {
  const { onCancel, onAdd, editedBlockData, isLoading, startLoading, finishLoading, property } = props;
  const [url, setUrl] = useState('');
  const [crowdSignalData, setCrowdSignalData] = useState<CrowdSignalValue | null>(null);
  const [isAddDisabled, setIsAddDisabled] = useState(true);

  useEffect(() => {
    if (url) {
      getUrlOembedData(url);
      setIsAddDisabled(false);
    }
  }, [url]);

  const getUrlOembedData = (crowdSignalUrl: string) => {
    startLoading();
    const oembedURL = `${OEMBED_URL_PREFIX}${encodeURI(crowdSignalUrl)}`;
    getOembedDataByProxy(oembedURL, url, BLOCK_TYPES.CROWD_SIGNAL, property.slug)
        .then(data => {
          finishLoading();
          setCrowdSignalData({ ...data as CrowdSignalValue, html: removeNoScriptTag((data as CrowdSignalValue).html) });
        })
        .catch(e => {
          finishLoading();
          setCrowdSignalData(null);
          Logger.error(e);
        });
  };

  const onInputChange = (e: any) => {
    setUrl(e.target.value);
    getUrlOembedData(e.target.value);
  };

  const onClear = () => {
    setUrl('');
    setIsAddDisabled(true);
  };

const removeNoScriptTag = (html: string) => {
  const htmlStringWithoutNoScriptTag = html.replace('<noscript>', '').replace('</noscript>', '');
  const insertHeightAndWidthPosition = htmlStringWithoutNoScriptTag.indexOf('src');
  return `${htmlStringWithoutNoScriptTag.slice(0, insertHeightAndWidthPosition)} height="500" width="100%" ${htmlStringWithoutNoScriptTag.slice(insertHeightAndWidthPosition)}`;
};

  const getPreviewComponent = () => {
    if (url === '' || isLoading) {
      return null;
    }
    return crowdSignalData
      ? <div dangerouslySetInnerHTML={{ __html: crowdSignalData.html }} css={css(getStylesObject().embed)} /> : //eslint-disable-line
      <Plugin.ErrorMsgComponent text={EMBED_URL_BROKEN_TEXT} />;
  };
  const onUrlInputKeyDown = (e: SyntheticEvent<HTMLElement, Event>) => {
    if (isEnterPressed(e) && crowdSignalData) {
      onAddButtonClick();
    }
  };

  const onAddButtonClick = () => {
    if (crowdSignalData) {
      onAdd(crowdSignalData);
    }
  };

  return (
    <Plugin.Container>
      <Plugin.Content>
        <Input
          value={url}
          placeholder={ENTER_CROWD_SIGNAL_PLACEHOLDER}
          style={pluginInputStyle}
          onChange={onInputChange}
          onKeyDown={onUrlInputKeyDown}
          onClear={onClear}
        />
        {getPreviewComponent() }
      </Plugin.Content>
      <Plugin.CopyrightInformation />
      <Plugin.Buttons onCancelClick={onCancel} onAddClick={onAddButtonClick} isAddDisabled={isAddDisabled} isCancelDisabled={false} addButtonText={pluginAddButtonTextHandler(editedBlockData)} />
    </Plugin.Container>
  );
};
