const TENANT = {
  MMSPORT: 'mmsport',
  MMPLUS: 'mmplus',
};

const PLAYER_ID_REGEX = /playerId=([^&|^"]*)/g;
const CONTENT_ID_REGEX = /contentId=([^&|^"]*)/g;
const ORG_ID_REGEX = /orgId=([^&|^"]*)/g;
const SUB_ID_REGEX = /subId=([^&|^"]*)/g;

export const extractMMPlayerFieldsFromEmbed = previewHtml => {
  const playerId = previewHtml.match(PLAYER_ID_REGEX)[0].replace(/playerId=/g, '');
  const contentId = previewHtml.match(CONTENT_ID_REGEX)[0].replace(/contentId=/g, '');
  return { playerId, contentId };
};

export const extractMiniPlayerFieldsFromEmbed = previewHtml => {
  const orgId = previewHtml.match(ORG_ID_REGEX)[0].replace(/orgId=/g, '');
  const contentId = previewHtml.match(CONTENT_ID_REGEX)[0].replace(/contentId=/g, '');
  const subIdMatch = previewHtml.match(SUB_ID_REGEX);

  return {
    orgId,
    contentId,
    ...subIdMatch ? { subId: subIdMatch[0].replace(/subId=/g, '') } : {},
  };
};

const MMSPORT_ORG_SLUG_IDS_LIST = {
  pmwz: '2',
  pmkz: '3',
  pmex: '4',
  interactive: '5',
  si: '6',
  pmpp: '7',
};

export const getOrgIdByTenant = (tenant, userOrgId) => {
  switch (tenant) {
    case TENANT.MMPLUS:
      return userOrgId;
    case TENANT.MMSPORT:
      return MMSPORT_ORG_SLUG_IDS_LIST[userOrgId] || 1;
    default:
      return 1;
  }
};

export const getSearchProperty = (userOrgId, property) => {
  if (userOrgId !== 'si' || property?.slug === 'si_swimsuit' || property?.slug === 'si_lifestyle') return property;
  return { slug: 'si-temp' };
};
