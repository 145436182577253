import { LOADING_STATES } from 'app.contants';
import {
  ChartsFormat,
  ChartsType,
  FilterId,
} from './dashboards.constants';
import { DashboardsPaths } from '../../reports.constants';
import { DateType } from '../../pages/components/DateRangeSetup/DateRangeSetup';

export interface ChartData {
  chartType: ChartsType
  chartLine: number
  chartFormat: ChartsFormat
  data: { [key: string]: any }
  compactNotation?: boolean
}

export interface ChartState {
  chartLoading: LOADING_STATES;
  chartError?: string;
  chartType: ChartsType;
  chartLine: number;
  chartData: any;
  chartName: string;
  chartFormat: ChartsFormat;
  compactNotation?: boolean
}

export interface FilterDataState {
  filterItemsLoading: boolean;
  filterItemsError?: string;
  filterListItems: string[];
}

export interface DisclaimerData {
  disclaimer?: string[]
  isLoading?: boolean
  error?: string
}

export interface DashboardState {
  chartsData: Record<string, ChartState>
}

export interface DashboardsSliceState {
  [DashboardsPaths.MMSSPRevenue]: DashboardState
  [DashboardsPaths.VoltaxRevenue]: DashboardState
  [DashboardsPaths.Homepage]: DashboardState
  [DashboardsPaths.RevenueOverview]: DashboardState
  [DashboardsPaths.RevenueOverviewSummary]: DashboardState
  [DashboardsPaths.UnifiedPlayerRevenue]: DashboardState
  filtersData: Record<FilterId, FilterDataState>
  disclaimerData: DisclaimerData
  pageLoaded: boolean
}

export enum FilterType {
  ExactlyMatching = 'Exactly matching',
  StartsWith = 'Starts with',
  Contains = 'Contains',
  IsNull = 'Is null',
  In = 'In',
  Regex = 'Regex',
}

export type Filter = {
  id: string;
  field: string;
  include: boolean;
  type: FilterType;
  data: string[];
}

export type DateRange = {
  field: string,
  type: DateType,
  from: string,
  to: string,
}
