import { ConfigsProvider } from './ConfigsProvider';
import { onRedirectCallback } from '../auth/auth0.utils';
import { Logger } from '../logger';

const AUTH0_CONFIG = ConfigsProvider.AUTH0_CONFIGS;
const LOGOUT_QUERY = 'logout';

export const getAuth0InitOption = () => {
  const currentUrl = window.location.href;
  const {
    AUTH0_CLIENT_ID: client_id,
    AUTH0_DOMAIN: domain,
    AUTH0_CONNECTION: connection,
    LOGIN_LOGO_SRC: logo_src,
    FAVICON_SRC: favicon_src,
    IS_SSO_CONNECTION: isSsoConnection,
    SSO_CONNECTION: ssoConnection,
  } = AUTH0_CONFIG;

  const authConnection = isSsoConnection ? ssoConnection : connection;
  const connectionSource = currentUrl.includes(LOGOUT_QUERY) ? connection : authConnection;
  return {
    domain,
    clientId: client_id,
    logoSrc: logo_src,
    faviconSrc: favicon_src,
    connection: connectionSource,
    isSsoConnection,
    ssoConnection,
    audience: `https://${domain}/api/v2/`,
    scope: 'openid groups permissions roles profile email user_metadata picture',
    Logger,
    onRedirectCallback,
  };
};
