import { AvailablePropertiesVoltax } from '../property/property.types';
import { GlobalRole, VoltaxRole } from './roles.constants';
import { UserRole } from './types';
import { LOCAL_HOST, MINUTEMEDIA_ORG_ID } from './user.constants';

export const formatUserRoles = (roles: { [key in UserRole]: unknown }): UserRole[] => {
  return Object.keys(roles) as UserRole[];
};

export const getOrgIdFromSubdomain = () => {
  const orgFromSubdomain = window.location.hostname.split('.')[0];
  return orgFromSubdomain === LOCAL_HOST ? MINUTEMEDIA_ORG_ID : orgFromSubdomain;
};

export const getUserOrgId = (orgId: string, roles: UserRole[]) => {
  const orgFromSubdomain = getOrgIdFromSubdomain();
  const isUserMegaAdmin = roles.includes(GlobalRole.MegaAdmin);
  return isUserMegaAdmin ? orgFromSubdomain : orgId;
};

export const hasRolePerProperty = (role: VoltaxRole, currentProperty: string, properties: AvailablePropertiesVoltax) => {
  return !!properties?.[role]?.[currentProperty];
};

export const hasRole = (roles: UserRole[], role: UserRole) => {
  return roles.includes(role);
};
