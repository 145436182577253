import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  buildQueryFromObject,
  isEmptyObject,
} from '@ftbpro/mm-admin-core-utils';
import { AppDispatch } from 'core/store';
import { networkAction } from 'core/middleware/networkMiddleware/networkMiddleware.actions';
import { ANALYTICS_INITIAL_STATE } from 'containers/contributorsAnalytics/analytics.constants';
import { pushGlobalNotification } from 'core/globalNotifications/store/globalNotifications.actions';
import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { mmNetworkService } from 'core/network/mmServicesApiProvider';
import { HttpMethod } from 'core/middleware/networkMiddleware/networkMiddleware.constans';
import {
  StatsFromServer,
  SiteStats,
  UserStats,
  DateFilterType,
  PaymentStats,
  Property,
  PostStatsData,
  PostQuery,
  TabKey,
  AuthorsFromServer,
} from './types';
import { AnalyticsServiceConfigsProvider } from '../services/analyticsServiceConfigsProvider';
import {
  formatPostsForTable,
  formatStatsForClient,
  formatAuthorsForFilter,
} from './analytics.formatter';
import { createStartEndDate } from './analytics.utils';
import { FAILED_POST_STATS } from '../analytics.strings';

const sliceScope = 'contributorsAnalytics';

const analyticsSlice = createSlice({
  name: sliceScope,
  initialState: ANALYTICS_INITIAL_STATE,
  reducers: {
    fetchStatsPending(state) {
      state.loading = true;
    },
    fetchStatsSuccess(state, action: PayloadAction<Partial<StatsFromServer>>) {
      state.stats = action.payload;
      state.totalStats = isEmptyObject(action.payload)
        ? ANALYTICS_INITIAL_STATE.totalStats
        : formatStatsForClient(action.payload);
      state.loading = false;
    },
    fetchStatsRejected(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setSiteStatsDate(state, action: PayloadAction<DateFilterType>) {
      state.siteStatsDate = action.payload;
    },
    setUserStatsDate(state, action: PayloadAction<DateFilterType>) {
      state.userStatsDate = action.payload;
    },
    setUserStatsTab(state, action: PayloadAction<TabKey>) {
      state.userStatsTab = action.payload;
    },
    setPaymentsStatsDate(state, action: PayloadAction<DateFilterType>) {
      state.paymentsStatsDate = action.payload;
    },
    setTotalStats(state, action: PayloadAction<SiteStats>) {
      state.totalStats = { ...state.totalStats, ...action.payload };
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPaymentsLoading(state, action: PayloadAction<boolean>) {
      state.paymentsLoading = action.payload;
    },
    setUsersLoading(state, action: PayloadAction<boolean>) {
      state.usersLoading = action.payload;
    },
    setPostsLoading(state, action: PayloadAction<boolean>) {
      state.postsLoading = action.payload;
    },
    fetchUserStatsPending(state) {
      state.usersLoading = true;
    },
    fetchUserStatsSuccess(state, action: PayloadAction<UserStats[]>) {
      state.usersLoading = false;
      state.userStats = action.payload;
    },
    fetchUserStatsRejected(state, action: PayloadAction<string>) {
      state.usersLoading = false;
      state.error = action.payload;
    },
    fetchPaymentsStatsPending(state) {
      state.paymentsLoading = true;
    },
    fetchPaymentsStatsSuccess(state, action: PayloadAction<PaymentStats[]>) {
      state.paymentsLoading = false;
      state.paymentsStats = action.payload;
    },
    fetchPaymentsStatsRejected(state, action: PayloadAction<string>) {
      state.paymentsLoading = false;
      state.error = action.payload;
    },
    fetchPostStatsPending(state) {
      state.postsLoading = true;
    },
    fetchPostStatsSuccess(state, action: PayloadAction<PostStatsData>) {
      state.error = '';
      state.postsLoading = false;
      state.postStats = formatPostsForTable(action.payload.articles);
      state.postPagination = action.payload.pagination;
    },
    fetchPostStatsRejected(state, action: PayloadAction<string>) {
      state.postsLoading = false;
      state.error = action.payload ?? FAILED_POST_STATS;
    },
    setPostStatsDate(state, action: PayloadAction<DateFilterType>) {
      const { startDate, endDate } = JSON.parse(action.payload.date);

      state.postQuery = { ...state.postQuery, startDate, endDate };
      state.postStatsDate = action.payload;
    },
    setPostQuery(state, action: PayloadAction<Partial<PostQuery>>) {
      state.postQuery = { ...state.postQuery, ...action.payload };
    },
    setPostProperty(state, action: PayloadAction<Property>) {
      state.postProperty = action.payload;
    },
    fetchAuthorsPending(state) {
      state.loading = true;
    },
    fetchAuthorsSuccess(state, action: PayloadAction<AuthorsFromServer[]>) {
      state.loading = false;
      state.authors = formatAuthorsForFilter(action.payload);
    },
    fetchAuthorsRejected(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setSiteStatsTab(state, action: PayloadAction<TabKey>) {
      state.siteStatsTab = action.payload;
    },
  },
});

export const fetchSiteStats = (property: string, sDate = '', eDate = '') => async (dispatch: AppDispatch) => {
    let startDate = sDate;
    let endDate = eDate;

    if (!startDate || !endDate) {
      const { start, end } = createStartEndDate();
      startDate = start;
      endDate = end;
    }

    const query = buildQueryFromObject({ startDate, endDate });

    await dispatch(
      networkAction({
        url: AnalyticsServiceConfigsProvider.getSiteStatsEndpoint(
          property,
          query,
        ),
        onPending: fetchStatsPending,
        onSuccess: fetchStatsSuccess,
        onFailure: fetchStatsRejected,
      }),
    );
  };

export const fetchUserStats = (userId: string, startDate: string, endDate: string) => async (dispatch: AppDispatch) => {
    const query = buildQueryFromObject({ startDate, endDate });

    await dispatch(
      networkAction({
        url: AnalyticsServiceConfigsProvider.getUserStatsEndpoint(
          userId,
          query,
        ),
        onPending: fetchUserStatsPending,
        onSuccess: fetchUserStatsSuccess,
        onFailure: fetchUserStatsRejected,
      }),
    );
  };

export const fetchPaymentsStats = (userId: string, startDate: string, endDate: string) => async (dispatch: AppDispatch) => {
    const query = buildQueryFromObject({ startDate, endDate });

    await dispatch(
      networkAction({
        url: AnalyticsServiceConfigsProvider.getUserInvoicesEndpoint(
          userId,
          query,
        ),
        onPending: fetchPaymentsStatsPending,
        onSuccess: fetchPaymentsStatsSuccess,
        onFailure: fetchPaymentsStatsRejected,
      }),
    );
  };

export const fetchPostStats = (
    property: string,
    queryParams: Partial<PostQuery>,
    path = '',
    signal?: AbortSignal,
  ) => async (dispatch: AppDispatch) => {
    dispatch(fetchPostStatsPending());
    const query = buildQueryFromObject(queryParams);
    const url = AnalyticsServiceConfigsProvider.getPostStatsEndpoint(
      property,
      query,
      path,
    );

    const options = {
      method: HttpMethod.Get,
      signal,
    };

    mmNetworkService
      .fetch(url, options)
      .then(res => {
        dispatch(fetchPostStatsSuccess(res));
      })
      .catch(e => {
        if (e.status === undefined) {
          dispatch(
            pushGlobalNotification({
              text: FAILED_POST_STATS,
              status: SNACK_BAR_STATUSES.ERROR,
              closeable: true,
              stayOpen: true,
            }),
          );
          dispatch(fetchPostStatsRejected(e));
        }
      });
  };

export const fetchAuthors = (property: string) => async (dispatch: AppDispatch) => {
    await dispatch(
      networkAction({
        url: AnalyticsServiceConfigsProvider.getUsersEndpoint(property),
        onPending: fetchAuthorsPending,
        onSuccess: fetchAuthorsSuccess,
        onFailure: fetchAuthorsRejected,
      }),
    );
  };

export const {
  fetchStatsPending,
  fetchStatsSuccess,
  fetchStatsRejected,
  setSiteStatsDate,
  setUserStatsDate,
  setPaymentsStatsDate,
  setPaymentsLoading,
  setUsersLoading,
  setTotalStats,
  setLoading,
  setPostsLoading,
  fetchUserStatsPending,
  fetchUserStatsSuccess,
  fetchUserStatsRejected,
  fetchPaymentsStatsPending,
  fetchPaymentsStatsSuccess,
  fetchPaymentsStatsRejected,
  fetchPostStatsPending,
  fetchPostStatsSuccess,
  fetchPostStatsRejected,
  setPostStatsDate,
  setPostProperty,
  setUserStatsTab,
  setPostQuery,
  fetchAuthorsPending,
  fetchAuthorsSuccess,
  fetchAuthorsRejected,
  setSiteStatsTab,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;
