import { DATE_FORMATS, formatDate } from '@ftbpro/mm-admin-core-utils';
import { ConfigsProvider } from 'core/configs/ConfigsProvider';
import { AvailablePropertiesVoltax } from 'core/store/property/property.types';
import { UserData } from 'core/store/user/types';

const formatProperties = (availableProperties: AvailablePropertiesVoltax) => {
  const propertiesArray = Object.values(availableProperties).reduce((acc: string[], properties) => {
    return [...acc, ...Object.keys(properties)];
  }, []);
  const propertiesSet = new Set(propertiesArray);
  return Array.from(propertiesSet).join(',');
};

export function shouldLoadAppcues() {
  return ConfigsProvider.FeatureFlags?.LoadAppcues;
}

export function initAppcues() {
  if (shouldLoadAppcues()) {
    window.AppcuesSettings = { enableURLDetection: true };
    const appcuesScript = document.createElement('script');
    appcuesScript.type = 'text/javascript';
    appcuesScript.src = '//fast.appcues.com/114698.js';
    document.body.prepend(appcuesScript);
  }
}

export function identifyUserInAppcues(userData: UserData, properties: AvailablePropertiesVoltax = {}) {
  const { userDetails, userRoles, orgId } = userData;
  const { username, name, userId, email, registrationDate } = userDetails;
  window.Appcues?.identify(userId, {
    username,
    name,
    email,
    roles: userRoles ? userRoles?.join(',') : '',
    organization: orgId,
    userRegistrationDate: registrationDate && formatDate(registrationDate, DATE_FORMATS.ISO_DATE),
    properties: formatProperties(properties),
  });
}
