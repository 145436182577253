import { Product } from 'core/constants';
import { stnLogoSlugs, VoltaxRole } from './roles.constants';
import { propertySelector } from '../property/property.selector';
import { hasRole, hasRolePerProperty } from './user.utils';
import { RootState } from '..';
import { UserSliceState } from './types';
import { HQRole } from './roles.constants.hq';

function getUserState(state: RootState): UserSliceState {
  return state.user;
}

function getUserDetails(state: RootState) {
  return getUserState(state).userDetails;
}

function hasCmsRolePerProperty(state: RootState, role: VoltaxRole) {
  const userProperties = propertySelector.availablePropertiesVoltax(state);
  const currentPropertySlug = propertySelector.currentPropertySlug(state, Product.CMS) || '';
  return hasRolePerProperty(role, currentPropertySlug, userProperties);
}

function isSTNUser(orgId: string) {
  return stnLogoSlugs.includes(orgId);
}

export const userSelector = {
  getUser(state: RootState) {
    return getUserDetails(state);
  },
  getUsername(state: RootState) {
    return getUserDetails(state)?.username;
  },
  getUserRoles(state: RootState) {
    return getUserState(state)?.userRoles;
  },
  getUserId(state: RootState) {
    return getUserDetails(state)?.userId ?? '';
  },
  getUserImage(state: RootState) {
    return getUserDetails(state)?.picture;
  },
  userRoles(state: RootState) {
    return getUserState(state).userRoles;
  },
  isUserCmsAdmin(state: RootState) {
    return hasCmsRolePerProperty(state, VoltaxRole.CmsManager);
  },
  isUserCmsEditor(state: RootState) {
    return hasCmsRolePerProperty(state, VoltaxRole.CmsEditor);
  },
  getUserOrgId(state: RootState) {
    return getUserState(state).orgId;
  },

  getUserToken(state: RootState) {
    return getUserState(state).rawTokenData ?? '';
  },

  isUserDataLoaded(state: RootState) {
    return getUserState(state).isUserDataLoaded;
  },

  isUserSeoGoneAdmin(state: RootState) {
    return hasRole(getUserState(state).userRoles, HQRole.SeoGoneAdmin);
  },

  getIsSTNUser(state: RootState) {
    return isSTNUser(getUserState(state).orgId);
  },
};
