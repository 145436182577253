import {
  DropdownListItemProps,
  SortingConfig,
} from '@ftbpro/mm-admin-ui-components';
import { Pagination } from 'core/types';

export interface AnalyticsSliceState {
  totalStats: SiteStats;
  stats: StatsFromServer;
  siteStatsDate: DateFilterType;
  paymentsStatsDate: DateFilterType;
  statsSortConfig: SortingConfig;
  loading: boolean;
  error: string;
  userStats: UserStats[];
  userStatsDate: DateFilterType;
  userStatsTab: TabKey;
  paymentsStats: PaymentStats[];
  paymentsLoading: boolean;
  usersLoading: boolean;
  postsLoading: boolean;
  postStats: PostStatsForTable[];
  postProperty: Property;
  postStatsDate: DateFilterType;
  postPagination: Pagination;
  postQuery: PostQuery;
  authors: DropdownListItemProps[];
  siteStatsTab: TabKey;
}

export interface SiteStats {
  nonExpertViews: string | number;
  siteViews: string | number;
  pubCount: number;
  activeUsers: UserForTable[];
}

export type ViewTier = {
  views: number;
  geoTier: number;
};

export interface StatsFromServer {
  nonExpertPageViews?: ViewTier[];
  siteViews?: ViewTier[];
  pubCount?: number;
  activeUsers?: ActiveUser[];
}

export type ActiveUser = {
  name: string;
  email: string | null;
  pubCount: number;
  lastPublished: number;
  views: ViewTier[] | never[];
};

export type UserForTable = {
  actions: { id: number };
  name: string;
  email: string | null;
  pubCount: number;
  lastPublished: string;
  geoTier1?: number;
  geoTier2?: number;
  geoTier3?: number;
};

export interface UserStats {
  siteName: string;
  siteId: string;
  pubCount: number;
  lastPublished: string;
  views: ViewTier[];
}

export type UserStatsForTable = {
  actions: { id: number };
  siteName: string;
  pubCount: number;
  geoTier1?: number;
  geoTier2?: number;
  geoTier3?: number;
  geoTier4?: number;
};

export interface DateFilterType extends DropdownListItemProps {
  date: string;
}

export interface PaymentStats {
  date: string;
  status: string;
  total: string;
  site: string;
}

export type PaymentStatsForTable = {
  actions: { id: number };
  month: string;
  amount: string;
  payments: PaymentStats[];
} | null;

export interface PostStatsData {
  articles: PostStats[];
  pagination: Pagination;
}

export type PostStats = {
  pageViews: number;
  url: string;
  title: string;
  author?: string;
};

export interface PostStatsForTable {
  pageViews: number;
  post: {
    url: string;
    title: string;
  };
  author?: string;
}

export interface Property {
  id: string;
  name: string;
  products: string | null;
  roles: string | null;
  createdAt: string;
  readonlyRoles: string | null;
}

export enum TabKey {
  Overview = 'overview',
  Post = 'post',
  Payments = 'payments',
}

export type PostQuery = {
  userId?: string;
  startDate: string;
  endDate: string;
  limit: number;
  search: string;
};

export enum PageDirection {
  Next = 'next',
  Prev = 'prev',
}

export type PropertyMap = {
  [propertyID: string]: Property;
}

export type AuthorsFromServer = {
  id: string;
  name: string;
};

export enum PageType {
  User = 'User',
  Stats = 'Stats',
}
