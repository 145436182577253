import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { isInThisStorySupported } from 'containers/cms/utils/cms.utils';
import { InThisStoryService } from 'containers/cms/services/inThisStoryServices/inThisStoryConfigServiceApiProvider';
import { getOrgIdFromSubdomain } from '../../../../../core/store/user/user.utils';
import { EchoNetworkService } from '../../services/echoServiceApiProvider';
import { Logger } from '../../../../../core/logger/index';
import { pushGlobalNotificationActionCreator } from '../../../../../core/globalNotifications/store/globalNotifications.actions';
import { getPostResponseFormatter } from '../../utils/echoServiceDataFormatter';
import {
  getFeedObjectByFeedName,
  isUrlChanged,
  prepareUnscheduledFeedsForServer,
  findRemovedFeedsFromOriginalFeedList,
  findRemovedAuthorsFromOriginalAuthorsList,
  getValidationUrlErrorMessage,
} from '../../utils/echo.utils';
import { getEchoCustomizationState } from './echoCustomization.selector';
import {
  SERVER_POST_STATUSES,
  PUSH_NOTIFICATION_TEXTS,
  POST_RECOMMENDATION_TOPIC_PREFIX,
} from '../../constants/echo.constants';
import { toggleSchedulePostModal, toggleUnfeatureFeedModal } from '../echoModals/echoModals.actions';
import { generateMetadataObject } from '../../components/echoCustomization/metadata/echoMetadata.utils';
import { CMSNavigator } from '../../../utils/CMSNavigator';
import { RecommendationServiceApiProvider } from '../../../services/recommendation/recommendationServiceApiProvider';
import { copyToClipboard } from '../../../../../core/utils/utils';
import { areInThisStoriesEqual, getRecommendedArticlesToFeature, getRecommendedArticlesToUnFeature, isPostPublished } from '../../utils/echoPublish.utils';
import { DEFAULT_ADS_CATEGORY } from '../../components/echoCustomization/metadata/echoMetadata.constants';
import { MatchesNetworkService } from '../../services/matchesServiceApiProvider';

export const CLEAR_ECHO_STORE = '[NEW ECHO] clear site data';
export const REQUEST_POST_DATA = '[NEW ECHO] requested post from server';
export const REQUEST_POST_SUCCESS = '[NEW ECHO] get post success';
export const REQUEST_POST_FAILURE = '[NEW ECHO] get post failure';
export const REQUEST_AVAILABLE_FEEDS = '[NEW ECHO] get post feeds';
export const REQUEST_AVAILABLE_FEEDS_SUCCESS = '[NEW ECHO] get post feeds success';
export const REQUEST_AVAILABLE_FEEDS_FAILURE = '[NEW ECHO] get post feeds failure';
export const ADD_CHANNEL_TO_POST = '[NEW ECHO] add feed to post';
export const ADD_SUGGESTED_CHANNEL_TO_POST = '[NEW ECHO] add suggested feed to post';
export const REMOVE_CHANNEL_FROM_POST = '[NEW ECHO] remove feed from post';
export const POST_PUBLISH_REQUEST = '[NEW ECHO] publish post to server';
export const POST_PUBLISH_SUCCESS = '[NEW ECHO] publish post to server success';
export const POST_PUBLISH_ERROR = '[NEW ECHO] publish post to server failure';
export const CHANGE_POST_PATH = '[NEW ECHO] change post path';
export const CHANGE_DFP_CATEGORY = '[NEW ECHO] change dfp category';
export const CHANGE_MAIN_CATEGORY = '[NEW ECHO] change main category';
export const SET_NO_INDEX = '[NEW_ECHO] set no index';
export const SET_EXCLUDE_FROM_RSS = '[NEW_ECHO] set exclude from RSS';
export const SET_EXCLUDE_FROM_RECOMMENDATIONS = '[NEW_ECHO] set exclude from Recommendations';
export const HIDE_OWNER = '[NEW_ECHO] hide owner';
export const SET_SPONSORED_CONTENT = '[NEW_ECHO] set sponsored content';
export const TOGGLE_SPONSORED_CONTENT = '[NEW_ECHO] toggle sponsored section';
export const REQUEST_AVAILABLE_ADS_CATEGORIES = '[NEW ECHO] get post ads categories';
export const REQUEST_AVAILABLE_ADS_CATEGORIES_SUCCESS = '[NEW ECHO] get post ads categories success';
export const REQUEST_AVAILABLE_ADS_CATEGORIES_FAILURE = '[NEW ECHO] get post ads categories failure';
export const ADD_COMMERCIAL_TAG = '[NEW ECHO] add commercial tag';
export const REMOVE_COMMERCIAL_TAG = '[NEW ECHO] remove commercial tag';
export const SET_FEATURED_TIME_TO_ARRAY_OF_FEEDS = '[NEW ECHO] set feature time to given feeds(destinations)';
export const PIN_TO_TOP = '[NEW ECHO] set pin to top time';
export const UNPIN_FEATURED_FEED = '[NEW ECHO] unpin featured feed';
export const REQUEST_AVAILABLE_AUTHORS = '[NEW ECHO] get authors';
export const REQUEST_AVAILABLE_AUTHORS_SUCCESS = '[NEW ECHO] get authors success';
export const REQUEST_AVAILABLE_AUTHORS_FAILURE = '[NEW ECHO] get authors failure';
export const REMOVE_ADDITIONAL_AUTHOR = '[NEW ECHO] remove additional author';
export const DRAFT_OPENED_FOR_EDITING_THROUGH_CUSTOMIZATION_SCREEN = '[NEW ECHO] editor opened draft for editing through customization screen';
export const POST_PATH_EDITED = '[NEW ECHO] post path edited';
export const PREVIEW_PAGE_OPEN = '[NEW ECHO] preview page open';
export const SUGGEST_PAGES_REQUEST = '[NEW ECHO] make a request to load suggested pages';
export const SUGGEST_PAGES_SUCCESS = '[NEW ECHO] successfully loaded suggested pages';
export const SUGGEST_PAGES_FAILURE = '[NEW ECHO] failure while loading suggested pages';
export const TOGGLE_MANUAL_RECOMMENDATIONS_CONTENT = '[NEW_ECHO] toggle manual recommendations section';
export const ADD_RECOMMENDED_ARTICLE = '[NEW ECHO] add recommended article';
export const REMOVE_RECOMMENDED_ARTICLE = '[NEW ECHO] remove recommended article';
export const MANUAL_RECOMMENDATION_REQUEST = '[NEW ECHO] get manual recommendation request';
export const MANUAL_RECOMMENDATION_SUCCESS = '[NEW ECHO] get manual recommendation success';
export const MANUAL_RECOMMENDATION_FAILURE = '[NEW ECHO] get manual recommendation failure';
export const CLEAR_MANUAL_RECOMMENDATION_LIST = '[NEW ECHO] clear manual recommendation list';
export const REQUEST_FEATURED_ARTICLES = '[NEW ECHO] get featured articles for current post';
export const REQUEST_FEATURED_ARTICLES_SUCCESS = '[NEW ECHO] get featured articles for current post success';
export const REQUEST_FEATURED_ARTICLES_FAILURE = '[NEW ECHO] get get featured articles for current post failure';
export const REQUEST_AVAILABLE_MAIN_CATEGORIES = '[NEW ECHO] request available main categories';
export const REQUEST_AVAILABLE_MAIN_CATEGORIES_SUCCESS = '[NEW ECHO] request available main categories success';
export const REQUEST_AVAILABLE_MAIN_CATEGORIES_FAILURE = '[NEW ECHO] request available main categories failure';
export const COPY_URL_TO_CLIPBOARD = '[NEW ECHO] copy url to dashboard';
export const SET_CANONICAL_URL = '[NEW_ECHO] set canonical url';
export const TOGGLE_CANONICAL_URL_SECTION = '[NEW_ECHO] toggle canonical url section';
export const TOGGLE_PUBLISH_DATE = '[NEW_ECHO] toggle publish date section';
export const SET_PUBLISH_DATE = '[NEW_ECHO] set publish date';
export const REQUEST_DEFAULT_TOPIC_AND_VERTICAL = '[NEW_ECHO] request default topic and vertical';
export const REQUEST_DEFAULT_TOPIC_AND_VERTICAL_SUCCESS = '[NEW_ECHO] request default topic and vertical success';
export const REQUEST_DEFAULT_TOPIC_AND_VERTICAL_FAILURE = '[NEW_ECHO] request default topic and vertical failure';
export const SET_TOPIC = '[NEW_ECHO] set topic';
export const SET_VERTICAL = '[NEW_ECHO] set vertical';
export const TOGGLE_BETTING_CONTENT_SECTION = '[NEW_ECHO] toggle betting content section';
export const SET_BETTING_CONTENT = '[NEW_ECHO] set betting content';
export const SET_MADDEN_DATA = '[NEW_ECHO] set maddenData'; // TODO: Remove once madden migration is done.
export const REMOVE_SELECTED_IN_THIS_STROY = '[NEW_ECHO] remove selected inThisStory';
export const ADD_SELECTED_IN_THIS_STROY = '[NEW_ECHO] add selected inThisStory';
const ERROR_TEXT = 'Error occurred while processing request';

export function clearEchoStore() {
  return dispatch => {
    dispatch({
      type: CLEAR_ECHO_STORE,
    });
  };
}

export function getPostData(postId, property) {
  return async dispatch => {
    dispatch({
      type: REQUEST_POST_DATA,
    });

    try {
      const currentOrganization = getOrgIdFromSubdomain();
      const [postData, inThisStoryData] = await Promise.all([
        EchoNetworkService.getPostData({ postId, property }),
        ...isInThisStorySupported(property) ? [InThisStoryService.getInThisStoryByOrganization(currentOrganization)] : [],
      ]);
      const availableInThisStoryList = inThisStoryData?.inThisStories || [];

      return dispatch({
        type: REQUEST_POST_SUCCESS,
        payload: getPostResponseFormatter(postData, availableInThisStoryList),
      });
    } catch (error) {
      Logger.error(error);
      dispatch(pushGlobalNotificationActionCreator({
        text: PUSH_NOTIFICATION_TEXTS.GET_POST_DATA_FAILURE,
        status: SNACK_BAR_STATUSES.ERROR,
      }));
      dispatch({
        type: REQUEST_POST_FAILURE,
        payload: error,
      });
      return CMSNavigator.toEchoDashboard();
    }
  };
}

export function getAvailableFeeds(property, callback) {
  return async dispatch => {
    dispatch({
      type: REQUEST_AVAILABLE_FEEDS,
    });

    try {
      const feeds = await EchoNetworkService.getAvailableFeeds(property);
      if (callback) {
        callback(feeds);
      }
      return dispatch({
        type: REQUEST_AVAILABLE_FEEDS_SUCCESS,
        payload: feeds,
      });
    } catch (error) {
      Logger.error(error);
      return dispatch({
        type: REQUEST_AVAILABLE_FEEDS_FAILURE,
        payload: error,
      });
    }
  };
}

export function addFeed(feedName, availableFeeds) {
  return dispatch => {
    dispatch({
      type: ADD_CHANNEL_TO_POST,
      payload: getFeedObjectByFeedName(feedName, availableFeeds),
    });
  };
}

export function addSuggestedFeed(feedName, availableFeeds) {
  return dispatch => {
    dispatch({
      type: ADD_SUGGESTED_CHANNEL_TO_POST,
      payload: getFeedObjectByFeedName(feedName, availableFeeds),
    });
  };
}

export function removeFeed(feed) {
  return dispatch => {
    dispatch({
      type: REMOVE_CHANNEL_FROM_POST,
      payload: feed,
    });
  };
}

export function removeUnfeauredFeed(feed) {
  return dispatch => {
    dispatch(toggleUnfeatureFeedModal({ isUnfeatureFeedModalVisible: false }));
    dispatch(removeFeed(feed));
  };
}

// TODO: should consider doing promise all for allupdates - and quit on error
export function publishPost() {
  return async (dispatch, getState) => {
    // TODO: this function should be separated to to smaller functions and be renamed to 'publishPostFlow'
    const { feeds, postData, postMetaData, originalPostData } = getEchoCustomizationState(getState());
    const { id, property, status, authors } = postData;
    const {
      noIndex,
      excludeFromRSS,
      canonicalUrl,
      commercialTags,
      adsCategory,
      sponsoredContent,
      isSponsoredContentEnabled,
      hideOwner,
      selectedRecommendedArticles,
      featuredArticlesForCurrentPost,
      additionalPostsTopic,
      isManualRecommendationsEnabled,
      mainCategories,
      publishDate,
      isPublishDateEnabled,
      topic,
      vertical,
      excludeFromRecommendations,
      bettingContent,
      isBettingContentEnabled,
    } = postMetaData;

    const { addedFeeds, featuredFeeds, scheduledFeeds } = feeds;

    const addedFeedsToFeature = addedFeeds.filter(feed => !feed.featureTime);
    const addedFeedsToSchedule = addedFeeds.filter(feed => !!feed.featureTime);
    const recommendedArticlesToFeature = isManualRecommendationsEnabled && selectedRecommendedArticles.length
      ? getRecommendedArticlesToFeature(selectedRecommendedArticles, [...featuredArticlesForCurrentPost]) : [];

    const recommendedArticlesToUnFeature = featuredArticlesForCurrentPost.length
      ? getRecommendedArticlesToUnFeature([...selectedRecommendedArticles], featuredArticlesForCurrentPost, isManualRecommendationsEnabled) : [];

    const additionalPostsTopicObj = additionalPostsTopic ? { name: additionalPostsTopic, slug: additionalPostsTopic } : { name: `${POST_RECOMMENDATION_TOPIC_PREFIX}${id}`, slug: `${POST_RECOMMENDATION_TOPIC_PREFIX}${id}` };
    const additionalPostsTopicForMetaData = isManualRecommendationsEnabled && selectedRecommendedArticles.length ? additionalPostsTopicObj.name : null;
    // feeds with no featureTime (both added and scheduled)

    const feedsToFeature = [
      ...addedFeedsToFeature,
      ...scheduledFeeds.filter(feed => !feed.featureTime),
    ];

    // feeds that were removed from featured and were not added back
    const feedsToUnfeature = [
      ...findRemovedFeedsFromOriginalFeedList({ addedFeeds: addedFeedsToFeature, currentFeedList: featuredFeeds, originalFeedList: originalPostData.featuredFeeds }),
    ];

    // feeds that have publish time (both added and scheduled)
    const feedsToSchedule = [
      ...addedFeedsToSchedule,
      ...scheduledFeeds.filter(feed => !!feed.featureTime),
    ];

    // feeds that have no publish time and were removed from original scheduled feed list
    const feedsToUnschedule = [
      ...findRemovedFeedsFromOriginalFeedList({ addedFeeds: addedFeedsToSchedule, currentFeedList: scheduledFeeds, originalFeedList: originalPostData.scheduledFeeds }),
      ...prepareUnscheduledFeedsForServer(scheduledFeeds),
    ];

    const authorsToRemove = findRemovedAuthorsFromOriginalAuthorsList({ original: originalPostData.authors.additional, added: authors.additional });

    dispatch({
      type: POST_PUBLISH_REQUEST,
    });

    try {
      await EchoNetworkService.updatePostExcludeFromRecommendations({
        postId: id,
        data: { excludeFromRecommendations },
        property,
      });

      if (isUrlChanged(postMetaData.postPath, originalPostData.path)) {
        dispatch({
          type: POST_PATH_EDITED,
        });
        await EchoNetworkService.updatePostPath({ postId: id, property, data: { newPath: postMetaData.postPath } });
      }

      if (authors.additional.length || authorsToRemove.length) {
        await EchoNetworkService.updatePostAuthors({
          postId: id,
          property,
          data: {
            additional: {
              add: authors.additional,
              remove: findRemovedAuthorsFromOriginalAuthorsList({ original: originalPostData.authors.additional, added: authors.additional }),
            },
          },
        });
      }

      await EchoNetworkService.addMainCategory({
        postId: id,
        property,
        data: {
          mainCategory: mainCategories.selectedMainCategory || '',
        },
      });

      if (!areInThisStoriesEqual(originalPostData.inThisStory, postMetaData.inThisStory)) {
        await EchoNetworkService.updateInThisStory({ property, postId: id, inThisStory: postMetaData.inThisStory });
      }

      await EchoNetworkService.updatePostMetadata({
        postId: id,
        data: {
          metaData: generateMetadataObject({
            noIndex,
            excludeFromRSS,
            canonicalUrl,
            commercialTags,
            adsCategory,
            sponsoredContent,
            isSponsoredContentEnabled,
            hideOwner,
            additionalPostsTopic: additionalPostsTopicForMetaData,
            owner: originalPostData.authors.owner,
            topic,
            vertical,
            bettingContent,
            isBettingContentEnabled,
          }),
        },
        property,
      });

      await EchoNetworkService.updatePostCustomCanonicalURL({
        postId: id,
        data: {
          customCanonicalURL: canonicalUrl,
        },
        property,
      });

      if (isPublishDateEnabled) {
        await EchoNetworkService.updatePostPublishDate({
          postId: id,
          data: {
            createdAt: publishDate,
            updatedAt: publishDate,
          },
          property,
        });
      }

      await EchoNetworkService.updateTaxonomy({
        property,
        postId: id,
        taxonomy: {
          customTopic: topic,
          customVertical: vertical,
        },
      });

      if (recommendedArticlesToUnFeature.length) {
        recommendedArticlesToUnFeature.map(async article => {
          await EchoNetworkService.updatePostFeeds(
            { postId: article.id, property, data: { unfeature: [additionalPostsTopicObj] } },
          );
        });
      }

      if (recommendedArticlesToFeature.length) {
        recommendedArticlesToFeature.map(async article => {
          await EchoNetworkService.updatePostFeeds(
            { postId: article.id, property, data: { feature: [additionalPostsTopicObj] } },
          );
        });
      }

      if (feedsToFeature.length || feedsToUnfeature.length) {
        await EchoNetworkService.updatePostFeeds({ postId: id, property, data: { feature: feedsToFeature, unfeature: isPostPublished(postData) ? feedsToUnfeature : [] } });
      }
      if (feedsToSchedule.length || feedsToUnschedule.length) {
        await EchoNetworkService.updatePostScheduledFeeds({ postId: id, property, data: { scheduleFeeds: feedsToSchedule, unscheduleFeeds: feedsToUnschedule } });
      }

      dispatch({
        type: POST_PUBLISH_SUCCESS,
        payload: isUrlChanged(postMetaData.postPath, originalPostData.path),
      });

      dispatch(pushGlobalNotificationActionCreator({
        text: status === SERVER_POST_STATUSES.NEW ? PUSH_NOTIFICATION_TEXTS.PUBLISHED : PUSH_NOTIFICATION_TEXTS.UPDATED,
        status: SNACK_BAR_STATUSES.SUCCESS,
      }));

      return CMSNavigator.toEchoDashboard();
    } catch (error) {
      dispatch({
        type: POST_PUBLISH_ERROR,
      });
      dispatch(getPostData(id, property));
      dispatch(pushGlobalNotificationActionCreator({
        text: PUSH_NOTIFICATION_TEXTS.FAILURE,
        status: SNACK_BAR_STATUSES.ERROR,
      }));
      return Logger.error(error);
    }
  };
}

// TODO: Remove once madden migration is done.
export function maddenPublishPost() {
  return async (dispatch, getState) => {
    const { postData, maddenData, originalPostData } = getEchoCustomizationState(getState());
    const { id, property, status } = postData;
    const isPublished = postData?.status === SERVER_POST_STATUSES.PUBLISHED;

    const feedsToFeature = [{ name: 'dummyFeedName', slug: 'dummyFeedSlug' }];

    dispatch({
      type: POST_PUBLISH_REQUEST,
    });

    try {
      const response = await MatchesNetworkService.isValidUrl({
        postId: id,
        data: {
          metaData: {
            owner: originalPostData.authors.owner,
            adsCategory: DEFAULT_ADS_CATEGORY,
            maddenData: {
              exampleArticleUrl: maddenData.exampleArticleUrl.value,
              articleFinalUrl: maddenData.articleFinalUrl.value,
              authors: maddenData.authors.filter(author => author.name && author.pageUrl),
              destinations: maddenData.destinations.filter(dest => dest.feedName && dest.pageUrl),
            },
          },
        },
        property,
      });

      if (!response.isValid) {
        throw (response.code);
      }

      if (!isPublished) {
        await EchoNetworkService.updatePostFeeds({ postId: id, property, data: { feature: feedsToFeature, unfeature: [] } });
      }

      await EchoNetworkService.updatePostMetadata({
        postId: id,
        data: {
          metaData: {
            owner: originalPostData.authors.owner,
            adsCategory: DEFAULT_ADS_CATEGORY,
            maddenData: {
              exampleArticleUrl: maddenData.exampleArticleUrl.value,
              articleFinalUrl: maddenData.articleFinalUrl.value,
              authors: maddenData.authors.filter(author => author.name && author.pageUrl),
              destinations: maddenData.destinations.filter(dest => dest.feedName && dest.pageUrl),
            },
          },
        },
        property,
      });

      dispatch({
        type: POST_PUBLISH_SUCCESS,
        payload: false,
      });

      dispatch(pushGlobalNotificationActionCreator({
        text: status === SERVER_POST_STATUSES.NEW ? PUSH_NOTIFICATION_TEXTS.PUBLISHED : PUSH_NOTIFICATION_TEXTS.UPDATED,
        status: SNACK_BAR_STATUSES.SUCCESS,
      }));

      return CMSNavigator.toEchoDashboard();
    } catch (error) {
      dispatch({
        type: POST_PUBLISH_ERROR,
      });
      dispatch(getPostData(id, property));
      const urlErrorMsg = getValidationUrlErrorMessage(error);
      dispatch(pushGlobalNotificationActionCreator({
        text: urlErrorMsg || PUSH_NOTIFICATION_TEXTS.FAILURE,
        status: SNACK_BAR_STATUSES.ERROR,
      }));
      return Logger.error(error);
    }
  };
}

export function changeEditUrl(postPath) {
  return dispatch => {
    dispatch({
      type: CHANGE_POST_PATH,
      payload: { postPath },
    });
  };
}

export function setNoIndex(noIndex) {
  return dispatch => {
    dispatch({
      type: SET_NO_INDEX,
      payload: { noIndex },
    });
  };
}

export function setExcludeFromRSS(excludeFromRSS) {
  return dispatch => {
    dispatch({
      type: SET_EXCLUDE_FROM_RSS,
      payload: { excludeFromRSS },
    });
  };
}

export function setExcludeFromRecommendations(excludeFromRecommendations) {
  return dispatch => {
    dispatch({
      type: SET_EXCLUDE_FROM_RECOMMENDATIONS,
      payload: { excludeFromRecommendations },
    });
  };
}

// TODO: Remove once madden migration is done.
export function setMaddenData(fieldName, value) {
  return dispatch => {
    dispatch({
      type: SET_MADDEN_DATA,
      payload: { fieldName, value },
    });
  };
}

export function setHideOwner(hideOwner) {
  return dispatch => {
    dispatch({
      type: HIDE_OWNER,
      payload: { hideOwner },
    });
  };
}

export function setSponsoredContent(sponsoredContent) {
  return dispatch => {
    dispatch({
      type: SET_SPONSORED_CONTENT,
      payload: { sponsoredContent },
    });
  };
}

export const toggleSponsoredContentSection = isSponsoredContentEnabled => {
  return dispatch => {
    dispatch({
      type: TOGGLE_SPONSORED_CONTENT,
      payload: { isSponsoredContentEnabled },
    });
  };
};

export function setCanonicalUrl(canonicalUrl) {
  return dispatch => {
    dispatch({
      type: SET_CANONICAL_URL,
      payload: { canonicalUrl },
    });
  };
}

export const toggleCanonicalUrlSection = isCanonicalUrlEnabled => {
  return dispatch => {
    dispatch({
      type: TOGGLE_CANONICAL_URL_SECTION,
      payload: { isCanonicalUrlEnabled },
    });
  };
};

export function getAvailableAdsCategories(property) {
  return async dispatch => {
    dispatch({
      type: REQUEST_AVAILABLE_ADS_CATEGORIES,
    });
    try {
      const availableAdsCategories = await EchoNetworkService.getAvailableAdsCategories(property);
      return dispatch({
        type: REQUEST_AVAILABLE_ADS_CATEGORIES_SUCCESS,
        payload: { availableAdsCategories },
      });
    } catch (error) {
      Logger.error(error);
      return dispatch({
        type: REQUEST_AVAILABLE_ADS_CATEGORIES_FAILURE,
        payload: error,
      });
    }
  };
}

export function getAvailableAuthors(property) {
  return async dispatch => {
    dispatch({
      type: REQUEST_AVAILABLE_AUTHORS,
    });
    try {
      const availableAuthors = await EchoNetworkService.getAvailableAuthors(property);
      return dispatch({
        type: REQUEST_AVAILABLE_AUTHORS_SUCCESS,
        payload: { availableAuthors },
      });
    } catch (error) {
      Logger.error(error);
      return dispatch({
        type: REQUEST_AVAILABLE_AUTHORS_FAILURE,
        payload: error,
      });
    }
  };
}

export function addCommercialTag(tag) {
  return dispatch => {
    dispatch({
      type: ADD_COMMERCIAL_TAG,
      payload: tag,
    });
  };
}

export function removeCommercialTag(tag) {
  return dispatch => {
    dispatch({
      type: REMOVE_COMMERCIAL_TAG,
      payload: tag,
    });
  };
}

export const setTopic = topic => {
  return dispatch => {
    dispatch({
      type: SET_TOPIC,
      payload: topic,
    });
  };
};

export const setVertical = vertical => {
  return dispatch => {
    dispatch({
      type: SET_VERTICAL,
      payload: vertical,
    });
  };
};

export function changeAdsCategory(adsCategory) {
  return dispatch => {
    dispatch({
      type: CHANGE_DFP_CATEGORY,
      payload: { adsCategory },
    });
  };
}

export function updatePostScheduledFeeds({ feeds }) {
  return dispatch => {
    dispatch(toggleSchedulePostModal({ isSchedulePostModalVisible: false }));

    return dispatch({
      type: SET_FEATURED_TIME_TO_ARRAY_OF_FEEDS,
      payload: { feeds },
    });
  };
}

export function pinToTop(feed) {
  return dispatch => {
    dispatch({
      type: PIN_TO_TOP,
      payload: { feed },
    });
  };
}

export function unpinFeaturedPost(feed) {
  return dispatch => {
    dispatch({
      type: UNPIN_FEATURED_FEED,
      payload: { feed },
    });
  };
}

export const removeAdditionalAuthor = username => dispatch => {
  dispatch({
    type: REMOVE_ADDITIONAL_AUTHOR,
    payload: {
      username,
    },
  });
};

export function openDraftForEdit(property, id) {
  return dispatch => {
    CMSNavigator.toDraftEditing(property, id);
    dispatch({
      type: DRAFT_OPENED_FOR_EDITING_THROUGH_CUSTOMIZATION_SCREEN,
    });
  };
}

export function openPreviewPage(property, id) {
  return dispatch => {
    CMSNavigator.toEchoPreview(property, id);
    dispatch({
      type: PREVIEW_PAGE_OPEN,
    });
  };
}

export const suggestPages = (property, text) => async dispatch => {
  dispatch({
    type: SUGGEST_PAGES_REQUEST,
  });
  try {
    const suggestPagesResponse = await RecommendationServiceApiProvider.getPagesRecommendation(property, text);
    return dispatch({
      type: SUGGEST_PAGES_SUCCESS,
      payload: {
        suggestedPages: suggestPagesResponse,
      },
    });
  } catch (error) {
    return dispatch({
      type: SUGGEST_PAGES_FAILURE,
      payload: {
        text: ERROR_TEXT,
        error,
      },
    });
  }
};

export const togglePublishDateSection = isPublishDateEnabled => {
  return dispatch => {
    dispatch({
      type: TOGGLE_PUBLISH_DATE,
      payload: { isPublishDateEnabled },
    });
  };
};

export const setPublishDate = publishDate => {
  return dispatch => {
    dispatch({
      type: SET_PUBLISH_DATE,
      payload: { publishDate },
    });
  };
};

export const toggleManualRecommendationsSection = isManualRecommendationsEnabled => {
  return dispatch => {
    dispatch({
      type: TOGGLE_MANUAL_RECOMMENDATIONS_CONTENT,
      payload: { isManualRecommendationsEnabled },
    });
  };
};

export const setBettingContent = bettingContent => {
  return dispatch => {
    dispatch({
      type: SET_BETTING_CONTENT,
      payload: { bettingContent },
    });
  };
};

export const toggleBettingContentSection = isBettingContentEnabled => {
  return dispatch => {
    dispatch({
      type: TOGGLE_BETTING_CONTENT_SECTION,
      payload: { isBettingContentEnabled },
    });
  };
};

export const clearRecommendationList = () => dispatch => {
  dispatch({
    type: CLEAR_MANUAL_RECOMMENDATION_LIST,
  });
};

export const getManualRecommendationsArticles = (property, text, draftId) => async dispatch => {
  dispatch({
    type: MANUAL_RECOMMENDATION_REQUEST,
  });
  try {
    const manualRecommendationsResponse = await RecommendationServiceApiProvider.getSearchPostsRecommendation(property, text);
    const filteredManualRecommendationsArticle = manualRecommendationsResponse.posts.filter(item => item.id !== draftId);
    return dispatch({
      type: MANUAL_RECOMMENDATION_SUCCESS,
      payload: {
        recommendedArticles: filteredManualRecommendationsArticle,
      },
    });
  } catch (error) {
    return dispatch({
      type: MANUAL_RECOMMENDATION_FAILURE,
      payload: {
        text: ERROR_TEXT,
        error,
      },
    });
  }
};

export function addRecommendedArticles(article) {
  return dispatch => {
    dispatch({
      type: ADD_RECOMMENDED_ARTICLE,
      payload: article,
    });
  };
}

export function removeRecommendedArticles(articleId) {
  return dispatch => {
    dispatch({
      type: REMOVE_RECOMMENDED_ARTICLE,
      payload: articleId,
    });
  };
}

export const getManualRecommendationsFeaturedArticles = (property, additionalPostsTopic, limit) => async dispatch => {
  dispatch({
    type: REQUEST_FEATURED_ARTICLES,
  });
  try {
    const FeaturedRecommendationsArticlesResponse = await EchoNetworkService.getFeaturedRecommendationArticles({ property, additionalPostsTopic, limit });
    return dispatch({
      type: REQUEST_FEATURED_ARTICLES_SUCCESS,
      payload: FeaturedRecommendationsArticlesResponse.posts,
    });
  } catch (error) {
    return dispatch({
      type: REQUEST_FEATURED_ARTICLES_FAILURE,
      payload: {
        text: ERROR_TEXT,
        error,
      },
    });
  }
};

export function copyUrlToClipboard(url) {
  return dispatch => {
    copyToClipboard(url);
    dispatch({
      type: COPY_URL_TO_CLIPBOARD,
      payload: url,
    });
  };
}

export function getAvailableMainCategories(property, postId) {
  return dispatch => {
    dispatch({
      type: REQUEST_AVAILABLE_MAIN_CATEGORIES,
    });
    return EchoNetworkService.getMainCategory({ property, postId })
      .then(availableMainCategories => {
        dispatch({
          type: REQUEST_AVAILABLE_MAIN_CATEGORIES_SUCCESS,
          payload: { availableMainCategories },
        });
      })
      .catch(() => {
        dispatch({
          type: REQUEST_AVAILABLE_MAIN_CATEGORIES_FAILURE,
        });
      });
  };
}

export function changeMainCategory(newlySelectedMainCategory) {
  return dispatch => {
    dispatch({
      type: CHANGE_MAIN_CATEGORY,
      payload: { newlySelectedMainCategory },
    });
  };
}

export function getDefaultTopicAndVertical(property) {
  return dispatch => {
    dispatch({
      type: REQUEST_DEFAULT_TOPIC_AND_VERTICAL,
    });
    return EchoNetworkService.getPropertySettings({ property })
      .then(propertySetting => {
        dispatch({
          type: REQUEST_DEFAULT_TOPIC_AND_VERTICAL_SUCCESS,
          payload: {
            topic: propertySetting.defaultTopic || null,
            supportsCustomTopic: propertySetting.supportsCustomTopic,
            vertical: propertySetting.supportsCustomVertical ? '' : propertySetting.vertical,
            supportsCustomVertical: propertySetting.supportsCustomVertical,
            propertySettingLoad: true,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: REQUEST_DEFAULT_TOPIC_AND_VERTICAL_FAILURE,
        });
      });
  };
}

export const removeInThisStory = inThisStoryToRemove => {
  return dispatch => dispatch({
    type: REMOVE_SELECTED_IN_THIS_STROY,
    payload: inThisStoryToRemove,
  });
};

export const addInThisStory = inThisStoryToAdd => {
  return dispatch => dispatch({
    type: ADD_SELECTED_IN_THIS_STROY,
    payload: inThisStoryToAdd,
  });
};
