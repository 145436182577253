import { OrganizationListItem } from '../../store/organization/organizationSlice';
import { PropertiesByRolesItem, PropertiesListItem } from '../../store/property/property.types';
import { Product } from '../../constants';

export const getOrganizationsByProduct = (organizations: OrganizationListItem[], product: Product) => organizations.filter(organization => organization.products?.includes(product));

export const findOrganizationByName = (organizations: OrganizationListItem[], organizationName: string) => organizations.find(organization => organization.name === organizationName);

export const findPropertyByName = (availableProperties: PropertiesByRolesItem[], propertyName: string) => availableProperties.find(property => property.name === propertyName);

export const findOrganizationById = (organizations: OrganizationListItem[], selectedOrganization: string) => organizations.find(organization => organization.id === selectedOrganization);

export const findPropertyBySlug = (availableProperties: PropertiesByRolesItem[], selectedProperty: string) => availableProperties.find(property => property.slug === selectedProperty);

export const getSelectedOrganization = (preSelectedOrganization: string | undefined, currentOrganization: OrganizationListItem | undefined | null, availableOrganizations: OrganizationListItem[]) => {
  const preSelectedOrganizationObj = preSelectedOrganization && findOrganizationById(availableOrganizations, preSelectedOrganization);
  const currentOrganizationObj = currentOrganization && findOrganizationById(availableOrganizations, currentOrganization.id);
  return preSelectedOrganizationObj || currentOrganizationObj || availableOrganizations[0];
};

export const getSelectedProperty = (preSelectedProperty: string | undefined, currentProperty: PropertiesByRolesItem | null | undefined, availableProperties: PropertiesByRolesItem[]) => {
  const preSelectedPropertyObj = preSelectedProperty && findPropertyBySlug(availableProperties, preSelectedProperty);
  const currentPropertyObj = currentProperty && findPropertyBySlug(availableProperties, currentProperty.slug);
  return preSelectedPropertyObj || currentPropertyObj || availableProperties[0];
};

export const getOrganizationsName = (organizations: OrganizationListItem[]) => {
  return organizations.map(organization => organization.name).sort();
};

export const getAvailablePropertiesNames = (availableProperties: PropertiesByRolesItem[]) => availableProperties.map(property => property.name).sort();

export const getPropertiesByRoles = (roles: string[] = [], properties: { [key: string]: { [key: string]: PropertiesListItem } }) => {
  const propertiesRoles: { [key: string]: PropertiesListItem } = roles.reduce((acc, roleId) => {
    const currentRoleProperties = properties[roleId] || {};
    return { ...acc, ...currentRoleProperties };
  }, {});

  const propertiesByRoles = Object.values(propertiesRoles).map(({ id, name }) => ({ slug: id, name }));
  return propertiesByRoles.sort((a, b) => a.name.localeCompare(b.name));
};
