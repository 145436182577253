import { OrganizationSliceState } from './organizationSlice';
import { Product } from '../../constants';
import { RootState } from '../index';

const getOrganizationsSliceState = (state: RootState) => state.organization as OrganizationSliceState;

export const organizationSelector = {
  availableOrganizations: (state: any) => getOrganizationsSliceState(state).availableOrganizations,
  availableTenants: (state: any) => getOrganizationsSliceState(state).availableTenants,
  currentTenant: (state: any) => getOrganizationsSliceState(state).currentTenant,
  currentOrganization: (state: any, product: Product) => getOrganizationsSliceState(state)[product]?.currentOrganization,
  error: (state: any) => getOrganizationsSliceState(state).error,
  loadingState: (state: any) => getOrganizationsSliceState(state).areOrganizationLoading,
};
