import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { EmbedBlock } from '../../shared/EmbedBlock';
import { CrowdSignalBlockProps } from '../crowdSignal.types';

export const CrowdSignalBlock = (props: CrowdSignalBlockProps) => {
  const [loading, setLoading] = useState(true);
  const blockRef: MutableRefObject<HTMLElement | null> = useRef(null);

  const isIframeInserted = () => {
    return blockRef.current && blockRef.current.querySelector('iframe');
  };

  useEffect(() => {
    if (isIframeInserted() && blockRef.current) {
      blockRef.current.querySelector('iframe')?.addEventListener('load', () => {
        setLoading(false);
      });
    }
  }, []);

  const embedBlockProps = { ...props, loading, blockRef };

  return <EmbedBlock {...embedBlockProps} />;
};
