import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { setLocalStorageData } from '@ftbpro/mm-admin-core-utils';
import { filterOutSiTemp, formatHqProperties, getCurrentPropertyLocalStorageKey } from './property.utils';
import { pushGlobalNotificationActionCreator } from '../../globalNotifications/store/globalNotifications.actions';
import { Product } from '../../constants';
import { AdministrationServiceConfigsProvider } from '../../services/administrationServiceConfigsProvider';
import { networkAction } from '../../middleware/networkMiddleware/networkMiddleware.actions';
import { PropertySliceState } from './property.types';

const FETCH_ERROR_TEXT = 'Sorry, we couldn\'t load any properties';

const SLICE_NAME = 'property';

export const initialState: PropertySliceState = {
  availablePropertiesHQ: {},
  availablePropertiesVoltax: {},
  arePropertiesLoading: false,
  error: null,
};

const propertySlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateCurrentProperty(state, action) {
      const { selectedProperty, product, userId } = action.payload;
      setLocalStorageData(getCurrentPropertyLocalStorageKey(userId, product), JSON.stringify(selectedProperty));
      state[product as Product] = { currentProperty: selectedProperty };
    },
    resetPropertiesByOrganization(state, action) {
      const { product } = action.payload;
      state.availablePropertiesHQ = {};
      state[product as Product] = { currentProperty: null };
    },
    fetchPropertiesByOrganizationSuccess(state, action) {
      state.availablePropertiesHQ = formatHqProperties(action.payload.properties);
    },
    fetchPropertiesByOrganizationRejected(state, action) {
      state.availablePropertiesHQ = {};
      state.error = action.payload;
    },
    fetchPropertiesByRolesPending(state) {
      state.arePropertiesLoading = true;
    },
    fetchPropertiesByRolesSuccess(state, action) {
      state.arePropertiesLoading = false;
      state.availablePropertiesVoltax = filterOutSiTemp(action.payload.properties);
    },
    fetchPropertiesByRolesRejected(state, action) {
      state.arePropertiesLoading = false;
      state.availablePropertiesVoltax = {};
      state.error = action.payload;
    },
  },
});

export const fetchPropertiesByRoles = createAsyncThunk<void,
  undefined>(
    `${SLICE_NAME}/fetchPropertiesByRoles`,
    async (arg, thunkAPI) => {
      const { dispatch } = thunkAPI;
      await dispatch(networkAction({
        url: AdministrationServiceConfigsProvider.getAdministrationServicePropertiesByRolesEndpoint(),
        onPending: fetchPropertiesByRolesPending,
        onSuccess: fetchPropertiesByRolesSuccess,
        onFailure: error => {
          dispatch(pushGlobalNotificationActionCreator({
            text: FETCH_ERROR_TEXT,
            status: SNACK_BAR_STATUSES.ERROR,
            stayOpen: true,
          }));
          return { type: fetchPropertiesByRolesRejected.type, payload: error };
        },
      }));
    },
  );

export const fetchPropertiesByOrganization = createAsyncThunk<void,
  { organizationId: string, productId: string }>(
    `${SLICE_NAME}/fetchPropertiesByOrganization`,
    async ({ organizationId, productId }, thunkAPI) => {
      const { dispatch } = thunkAPI;
      await dispatch(networkAction({
        url: AdministrationServiceConfigsProvider.getOrganizationPropertiesEndpoint(organizationId, productId),
        onSuccess: fetchPropertiesByOrganizationSuccess,
        onFailure:
          error => {
            dispatch(pushGlobalNotificationActionCreator({
              text: FETCH_ERROR_TEXT,
              status: SNACK_BAR_STATUSES.ERROR,
              stayOpen: true,
            }));
            return { type: fetchPropertiesByOrganizationRejected.type, payload: error };
          },
      }));
    },
  );

export const {
  updateCurrentProperty,
  resetPropertiesByOrganization,
  fetchPropertiesByRolesPending,
  fetchPropertiesByRolesSuccess,
  fetchPropertiesByRolesRejected,
  fetchPropertiesByOrganizationSuccess,
  fetchPropertiesByOrganizationRejected,
} = propertySlice.actions;

export default propertySlice.reducer;
