function readConfigObject() {
  const configObject = document.getElementById('config');
  return configObject ? JSON.parse(document.getElementById('config').innerHTML) : null;
}

// TODO - refactor ConfigsProvider
// try to enforce the usage of ConfigsProvider to ConfigProvider classes only...

let config;

function getConfig() {
  if (!config) {
    config = readConfigObject();
  }
  return config;
}

export class ConfigsProvider {
  static get AUTH0_CONFIGS() {
    return {
      AUTH0_CLIENT_ID: getConfig().AUTH0_CLIENT_ID,
      AUTH0_DOMAIN: getConfig().AUTH0_DOMAIN,
      AUTH0_CONNECTION: getConfig().AUTH0_CONNECTION,
      LOGIN_LOGO_SRC: getConfig().LOGIN_LOGO_SRC,
      FAVICON_SRC: getConfig().FAVICON_SRC,
      IS_SSO_CONNECTION: getConfig().IS_SSO_CONNECTION,
      SSO_CONNECTION: getConfig().SSO_CONNECTION,
    };
  }

  static get CLIENT_ID_KEY() {
    return getConfig().CLIENT_KEY;
  }

  static get USE_CACHE() {
    return getConfig().USE_CACHE;
  }

  static get ECHO_API_BASE_URL() {
    return getConfig().ECHO_API_BASE_URL;
  }

  static get NEW_ECHO_API_BASE_URL() {
    return getConfig().NEW_ECHO_API_BASE_URL;
  }

  static get CONTENT_ENRICHMENT_API_BASE_URL() {
    return getConfig().CONTENT_ENRICHMENT_API_BASE_URL;
  }

  static get TAGS_API_BASE_URL() {
    return getConfig().TAGS_API_BASE_URL;
  }

  static get EDITOR_API_BASE_URL() {
    return getConfig().EDITOR_API_BASE_URL;
  }

  static get MONTI_API_BASE_URL() {
    return getConfig().MONTI_API_BASE_URL;
  }

  static get CRAFT_API_BASE_URL() {
    return getConfig().CRAFT_API_BASE_URL;
  }

  static get FACTS_SERVICE_API_BASE_URL() {
    return getConfig().FACTS_SERVICE_API_BASE_URL;
  }

  static get CRAFT_GATEWAY_SERVICE_API_BASE_URL() {
    return getConfig().CRAFT_GATEWAY_SERVICE_API_BASE_URL;
  }

  static get EDITOR_LINK_PREFIX() {
    return getConfig().EDITOR_LINK_PREFIX;
  }

  static get IS_LEGACY_EDITOR() {
    return getConfig().IS_LEGACY_EDITOR;
  }

  static get ENV() {
    return getConfig().NODE_ENV;
  }

  static get SENTRY_DSN() {
    return getConfig().SENTRY_DSN;
  }

  static get SNETRY_ORG_SLUG() {
    return getConfig().SNETRY_ORG_SLUG;
  }

  static get TENANT() {
    return getConfig().TENANT;
  }

  static get VMS_IMPORT_BASE_URL() {
    return getConfig().VMS_IMPORT_BASE_URL;
  }

  static get VMS_VIDEO_BASE_URL() {
    return getConfig().VMS_VIDEO_BASE_URL;
  }

  static get VMS_PLAYERS_BASE_URL() {
    return getConfig().VMS_PLAYERS_BASE_URL;
  }

  static get SYRINGE_BASE_URL() {
    return getConfig().SYRINGE_BASE_URL;
  }

  static get QUERY_BUILDER_API_BASE_URL() {
    return getConfig().QUERY_BUILDER_API_BASE_URL;
  }

  static get DATA_SOURCES_MANAGEMENT_API_BASE_URL() {
    return getConfig().DATA_SOURCES_MANAGEMENT_API_BASE_URL;
  }

  static get QUERY_BUILDER_EXTERNAL_USERS_API_BASE_URL() {
    return getConfig().QUERY_BUILDER_USERS_API_BASE_URL;
  }

  static get ANALYTICS_API_BASE_URL() {
    return getConfig().ANALYTICS_API_BASE_URL;
  }

  static get BIDDERS_API_BASE_URL() {
    return getConfig().BIDDERS_API_BASE_URL;
  }

  static get POLICY_API_BASE_URL() {
    return getConfig().POLICY_API_BASE_URL;
  }

  static get HQ_ANALYTICS_USERS_API_BASE_URL() {
    return getConfig().ANALYTICS_USERS_API_BASE_URL;
  }

  static get INSIGHT_SERVICE_BASE_URL() {
    return getConfig().INSIGHT_SERVICE_BASE_URL;
  }

  static get MARKETPLACE_PUBLISHER_BASE_URL() {
    return getConfig().MARKETPLACE_PUBLISHER_BASE_URL;
  }

  static get ADMINISTRATION_SERVICE_API_BASE_URL() {
    return getConfig().ADMINISTRATION_SERVICE_API_BASE_URL;
  }

  static get ADMINISTRATION_EXTERNAL_SERVICE_API_BASE_URL() {
    return getConfig().ADMINISTRATION_EXTERNAL_SERVICE_API_BASE_URL;
  }

  static get ORGANIZATION_EXTERNAL_SERVICE_API_BASE_URL() {
    return getConfig().ORGANIZATION_EXTERNAL_SERVICE_API_BASE_URL;
  }

  static get ORGANIZATION_SERVICE_API_BASE_URL() {
    return getConfig().ORGANIZATION_SERVICE_API_BASE_URL;
  }

  static get NEW_ORGANIZATION_EXTERNAL_SERVICE_API_BASE_URL() {
    return getConfig().NEW_ORGANIZATION_EXTERNAL_SERVICE_API_BASE_URL;
  }

  static get PARTNERS_EXTERNAL_SERVICE_API_BASE_URL() {
    return getConfig().PARTNERS_EXTERNAL_SERVICE_API_BASE_URL;
  }

  static get OPTIONS_CONFIGURATIONS_SERVICE_API_BASE_URL() {
    return getConfig().OPTIONS_CONFIGURATIONS_SERVICE_API_BASE_URL;
  }

  static get PROMOTIONS_SERVICE_API_BASE_URL() {
    return getConfig().PROMOTIONS_SERVICE_API_BASE_URL;
  }

  static get SITE_POLICY_SERVICE_API_BASE_URL() {
    return getConfig().SITE_POLICY_SERVICE_API_BASE_URL;
  }

  static get FeatureFlags() {
    return getConfig().featureFlags || {};
  }

  static get HELP_CENTER_URL() {
    return getConfig().HELP_CENTER_URL;
  }

  static get ZENDESK_SCRIPT_KEY() {
    return getConfig().ZENDESK_SCRIPT_KEY;
  }

  static get ZENDESK_SHARED_SECRET() {
    return getConfig().ZENDESK_SHARED_SECRET;
  }

  static get SENTRY_LOG_LEVEL() {
    return getConfig().SENTRY_LOG_LEVEL;
  }

  static get ERRORS_TO_IGNORE() {
    return getConfig().ERRORS_TO_IGNORE;
  }

  static get CONSOLE_LOG_LEVEL() {
    return getConfig().CONSOLE_LOG_LEVEL;
  }

  static get REDIRECTION_SERVICE_API_BASE_URL() {
    return getConfig().REDIRECTION_SERVICE_API_BASE_URL;
  }

  static get FULL_STORY_ORG_ID() {
    return getConfig().FULL_STORY_ORG_ID;
  }

  static get MENU_LOGO_SRC() {
    return getConfig().MENU_LOGO_SRC;
  }

  static get SETUP_SERVICE_API_BASE_URL() {
    return getConfig().SETUP_SERVICE_API_BASE_URL;
  }

  static get BID_SERVICE_API_BASE_URL() {
    return getConfig().BID_SERVICE_API_BASE_URL;
  }

  static get AUDIT_SERVICE_API_BASE_URL() {
    return getConfig().AUDIT_SERVICE_API_BASE_URL;
  }

  static get AUDIT_EXTERNAL_SERVICE_API_BASE_URL() {
    return getConfig().AUDIT_EXTERNAL_SERVICE_API_BASE_URL;
  }

  static get VMS_INJECTIONS_GATEWAY_SERVICE_API_BASE_URL() {
    return getConfig().VMS_INJECTIONS_GATEWAY_SERVICE_API_BASE_URL;
  }

  static get VMS_PLAYERS_GATEWAY_SERVICE_API_BASE_URL() {
    return getConfig().VMS_PLAYERS_GATEWAY_SERVICE_API_BASE_URL;
  }

  static get VMS_PLAYLISTS_GATEWAY_SERVICE_API_BASE_URL() {
    return getConfig().VMS_PLAYLISTS_GATEWAY_SERVICE_API_BASE_URL;
  }

  static get VMS_VIDEOS_GATEWAY_SERVICE_API_BASE_URL() {
    return getConfig().VMS_VIDEOS_GATEWAY_SERVICE_API_BASE_URL;
  }

  static get TENANTS_LIST() {
    return getConfig().TENANTS_LIST;
  }

  static get VMS_PLAYLISTS_BASE_URL() {
    return getConfig().VMS_PLAYLISTS_BASE_URL;
  }

  static get VIDEO_DELIVERY_BASE_URL() {
    return getConfig().VIDEO_DELIVERY_BASE_URL;
  }

  static get REDIRECTION_EXTERNAL_SERVICE_API_BASE_URL() {
    return getConfig().REDIRECTION_EXTERNAL_SERVICE_API_BASE_URL;
  }

  static get SENTRY_ALLOW_URLS() {
    return getConfig().SENTRY_ALLOW_URLS;
  }

  static get VMS_VERSION_MANAGEMENT_BASE_URL() {
    return getConfig().VMS_VERSION_MANAGEMENT_BASE_URL;
  }

  static get ONBOARDING_API_BASE_URL() {
    return getConfig().ONBOARDING_API_BASE_URL;
  }

  static get EXPERIMENTS_SERVICE_API_BASE_URL() {
    return getConfig().EXPERIMENTS_SERVICE_API_BASE_URL;
  }

  static get ADS_TXT_SERVICE_API_BASE_URL() {
    return getConfig().ADS_TXT_SERVICE_API_BASE_URL;
  }

  static get VMS_VIDEO_BUILDER_GATEWAY_SERVICE_API_BASE_URL() {
    return getConfig().VMS_VIDEO_BUILDER_GATEWAY_SERVICE_API_BASE_URL;
  }

  static get AD_MANAGEMENT_SERVICE_BASE_URL() {
    return getConfig().AD_MANAGEMENT_SERVICE_BASE_URL;
  }

  static get HOMEPAGE_PLAYLIST_SRC() {
    return getConfig().HOMEPAGE_PLAYLIST_SRC;
  }

  static get CONTENT_ENRICHMENT_EMBEDS_SRC() {
    return getConfig().CONTENT_ENRICHMENT_EMBEDS_SRC;
  }

  static get CONTRIBUTORS_ANALYTICS_API_BASE_URL() {
    return getConfig().CONTRIBUTORS_ANALYTICS_API_BASE_URL;
  }

  static get BULK_DELETE_EXTERNAL_SERVICE_API_BASE_URL() {
    return getConfig().BULK_DELETE_EXTERNAL_SERVICE_API_BASE_URL;
  }

  static get BULK_DELETE_SERVICE_API_BASE_URL() {
    return getConfig().BULK_DELETE_SERVICE_API_BASE_URL;
  }

  static get PLAYER_SELECTOR_SERVICE_URL() {
    return getConfig().PLAYER_SELECTOR_SERVICE_URL;
  }

  static get SMART_GENERATION_SERVICE_BASE_URL() {
    return getConfig().SMART_GENERATION_SERVICE_BASE_URL;
  }

  static get ALERTS_API_BASE_URL() {
    return getConfig().ALERTS_API_BASE_URL;
  }

  static get MATCHES_SERVICES_BASE_URL() {
    return getConfig().MATCHES_SERVICES_BASE_URL;
  }

  static get MZ_PUBLISHERS_API_BASE_URL() {
    return getConfig().MZ_PUBLISHERS_API_BASE_URL;
  }

  static get STN_LOGO() {
    return getConfig().STN_LOGO;
  }
}
