import React from 'react';
import { isEmpty, isEmptyObject, isUndefined } from '@ftbpro/mm-admin-core-utils';
import { UNAUTHORIZED_ERROR_DESCRIPTION } from '../errorPage/errorPage.constants';
import { getOrgIdFromSubdomain } from '../../store/user/user.utils';
import { OrganizationIndication } from '../errorPage/OrganizationIndication';

const userHasAllowedRole = (allowedRoles = [], userRoles) => isEmpty(allowedRoles) || allowedRoles.some(allowedRole => userRoles.includes(allowedRole));
const userHasPropertyForAllowedRoles = (allowedRoles = [], userProperties) => {
  return isEmpty(allowedRoles) || allowedRoles.some(allowedRole => {
    return !isUndefined(userProperties?.[allowedRole]) && !isEmptyObject(userProperties?.[allowedRole]);
  });
};

const validateProperties = (allowNoProperties, userProperties, allowedRoles) => {
  return allowNoProperties || userHasPropertyForAllowedRoles(allowedRoles, userProperties);
};

const userHasOrgPermission = (userOrgId, allowNoOrganization) => allowNoOrganization || userOrgId === getOrgIdFromSubdomain();
export const authorizeRoute = (userPermission, routeRestrictions) => {
  const { userRoles, userProperties, userOrgId } = userPermission;
  const { allowedRoles, allowNoProperties, allowNoOrganization } = routeRestrictions;

  if (!userHasOrgPermission(userOrgId, allowNoOrganization)) {
    return {
      isAuthorized: false,
      error: UNAUTHORIZED_ERROR_DESCRIPTION.NO_ORG_PERMISSION,
      message: <OrganizationIndication userOrgId={userOrgId} />,
    };
  }
  if (isEmpty(userRoles)) {
    return {
      isAuthorized: false,
      error: UNAUTHORIZED_ERROR_DESCRIPTION.NO_ROLES,
    };
  }

  if (!userHasAllowedRole(allowedRoles, userRoles)) {
    return {
      isAuthorized: false,
      error: UNAUTHORIZED_ERROR_DESCRIPTION.NO_ROLES,
    };
  }

  if (!validateProperties(allowNoProperties, userProperties, allowedRoles)) {
    return {
      isAuthorized: false,
      error: UNAUTHORIZED_ERROR_DESCRIPTION.NO_PROPERTIES,
    };
  }

  return {
    isAuthorized: true,
    error: null,
  };
};
